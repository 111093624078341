.w-page-tab {
    //width: 100%;
    border-bottom: 1px solid fade-out($primary-color-1, .85);
    padding-left: 2rem;
    margin-top: -1rem;
    margin-bottom: 1rem;
    .page-tab {
        display: flex;
        //padding: 2rem;
    }
    .large {
        background: none;
        border: 0;
        width: 11rem !important;
        position: relative;
        border: 0 !important;
        &:before {
            color: fade-out($primary-color-1, .5);
        }
        &:hover {
            &:before {
                color: $primary-color-1;
            }
        }
        &.i-unit {
            &:before {
                font-size: 2.5rem;
            }
        }
        &.is-selected {
            color: $accent-2;
            &:before {
                color: $accent-2;
                @include icon-glow($accent-2);
            }
            &:after {
                content: '';
                @include icon-glow($accent-2);
                background-color: $accent-2;
                width: 100%;
                height: 3px;
                position: absolute;
                bottom: -2px;
                margin-left: 0;
                left: 0;
                right: 0;
                box-shadow: 0px 0px 10px 0px fade-out($accent-2, .7);
            }
        }
    }
}