 .w-form {
    display: block;
    width: 50rem;
    min-width: 45rem;
    margin: 0 auto 6rem;
    @include shadow-darker;
     border-radius: $corner;
     &.margin-top {
        margin-top: 5rem;
    }
    &.center {
        /*
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

         */
    }
    &.w-form-large {
        width: auto;
        margin-bottom: 4rem;
        @include desktop-medium {
            width: 70%;
        }
        .w-form-body {
            display: flex;
            &.center {
                justify-content: center;
            }
            &.btn-only {
                margin: 2rem;
            }
        }
        .content-list-2row {
            flex-flow: row wrap;
            .form-body {
                width: calc((100% / 2) - 21px);
                &:nth-child(2n+1) {
                    border-left: none;
                }
            }
            .close-button {
                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-top: 1rem;
                color: $secondary-3;
                opacity: .4;
                &:before {
                    color: $secondary-3;
                }
                &:hover {
                    opacity: 1;
                    transition: opacity .3s ease;
                }
            }
        }
        .form-body {
            width: 50%;
            &:first-of-type {
                //border-right: 1px solid $border-light;
            }
            &.full-width {
                width: 100%;
                border-color: $accent-1;
            }
        }
    }
    .form-header {
        height: 6rem;
        box-sizing: border-box;
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //border: 1px solid $border-light;
        border-bottom: 0;
        border-radius: $corner $corner 0 0;
        background-color: lighten($primary-color-4, 15%);
        .form-title {
            @include font-family-custom();
            @include font-style(1.1em, 700, $accent-1-text);
            @include icon-glow($primary-color-5);
            text-transform: uppercase;
            margin-right: 2rem;
            display: flex;
            align-items: center;
            &:before {
                color: $accent-1-text;
                margin-right: .75rem;
                font-size: 2em;
                @include icon-glow($primary-color-3);
            }
            &.i-content {
                &:before {
                    font-size: 2.5em;
                }
            }
            &.i-sspConfig {
                &:before {
                    font-size: 2.5em;
                }
            }
        }
        .secondary {
            background-color: $primary-color-1;
        }
        .close-button {
            &:before {
                color: $accent-1-text;
                font-size: 1.5em;
            }
        }
    }
    .close-button {
        &:before {
            color: $primary-color-3;
            font-size: 2em;
        }
    }
    .form-body {
        //@include gradient-ver(lighten($primary-color-5, 7%), lighten($primary-color-5, 10%), $primary-color-3);
        background-color: lighten($primary-color-4, 10%);
        padding: 2rem 1rem 1rem 1rem;
        text-align: center;

        &.scroll {
            overflow-y: scroll;
            max-height: 35rem;
            margin-right: 3rem;

        }
        &:first-of-type {
           border-right: 0;
        }
    }
     .form-body + .form-body {
         border-left: 1px solid $border-light;
     }
     .w-form-body + .ads-behaviour-data {
         border-top: 1px solid $border-light;
     }
    .form-group-password {
        padding: 0 1rem .5rem 1rem;
        margin-right: -1rem;
        margin-left: -1rem;
        margin-bottom: 0;
        border-top: 1px solid $border-light;
        border-bottom: 1px solid $border-light;
        @include transition();
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        background-color: darken($primary-color-3, 1);
        &.is-open {
            max-height: 40rem;
            padding-top: 1.5rem;
            margin-bottom: 2rem;
            opacity: 1;
        }
    }
    .margin-top {
        margin-top: .75rem;
        display: flex;
        .btn {
            margin-right: 1rem;
        }
    }
    .form-check {

    }
    .form-check-inline {
        display: inline-block;
        margin: 0 1.5rem 0 0;
        width: 45%;
        &.width-auto {
            width: auto;
        }
    }
    .form-footer {
        display: flex;
        justify-content: center;
        padding: 1rem 2rem;
        background-color: lighten($primary-color-4, 5%);
        border-radius: 0 0 $corner $corner;
        //border: 1px solid fade-out($accent-1, .5);
        //border-top: 0;
        //background-color: $primary-color-5;
    }
    .form-sub-title {
        @include font-family-custom();
        @include font-style(1.2em, 700, $accent-2);
        text-transform: uppercase;
        display: block;
        margin: 0 auto;
        margin-bottom: 1.25rem;
    }
    .form-text {
        @include font-family-custom();
        @include font-style(1.2em, 400, $primary-color-1);
        text-align: left;
        margin-bottom: 1rem;
        &.highlight {
            font-weight: 700;
        }
        &.tip-warning {
            color: $accent-2;
            text-align: center;
            margin: 1.6rem auto 3.2rem;
        }
    }
     
     ul.ads-behaviour-list {
         display: flex;
         width: 100%;
         flex-flow: row wrap;
         align-items: flex-start;
         row-gap: 4px;
         li {
             list-style: disc;
             width: 40%;
             padding-right: 8px;
             margin-inline-start: 2rem;
             flex-grow: 1;
             box-sizing: border-box;
             @include font-family-custom();
             @include font-style(1.2em, 400, $primary-color-1);
         }
     }
}



/* element form */

.form-group {
    margin: 0 2rem 1.5rem;
    text-align: left;
    &:last-of-type {
        //margin-bottom: 2rem;
    }
    .with-tooltips {
        display: flex;
        align-items: center;
        .w-tooltips-adv {
            margin-left: 1.5rem;
            padding-left: 0;
        }
        .tooltips-adv {
            &:before {
                border-color: transparent transparent lighten($primary-color-4, 25%) transparent;
            }
        }
    }
    &.form-sentence {
        flex-wrap: wrap;
        justify-content: flex-start;
        .form-label {
            margin-left: .5rem;
            width: auto;
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
    .sub-header & {
        //margin-bottom: 0;
    }
    &.wrap {
        flex-wrap: wrap;
    }
    .nowrap {
         flex-wrap: nowrap;
    }
    &.border {
        padding-bottom: .75rem;
        border-bottom: 1px solid $border-light;
    }
    &.border-top {
       padding-top: 1rem;
       border-top: 1px solid $border-light;
    }
    &.adding {
        margin-bottom: 1rem;
    }
    .from-group-add {
        display: flex;
        margin: .5rem 0;
        padding-bottom: .5rem;
        flex: 1 100%;
        flex-wrap: wrap;
        border-bottom: 1px solid $border;
        select {
            flex: 1 auto;
        }
        .border {
            padding-bottom: .5rem;
            border-bottom: 1px solid $border;
        }
        .form-group {
            width: 100%;
            margin: 0 0 .75rem 0;
        }
        .input-text {
            margin-right: .5rem;
        }
        .right {
            display: flex;
            justify-content: flex-end;
            .btn {
                width: 3rem !important;
            }
        }
    }
}

.row { /* form-group */
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
        margin-right: .5rem;
    }
    &.margin-bottom {
       margin-bottom: 2rem !important;
    }
    .form-label {
        margin-right: .5rem;
        margin-bottom: 0;
        width: 25%;
        &.legend {
            width: fit-content;
        }
    }
    .input-text, .input-pass, select {
        width: 75%;
    }
    & > div select {
        width: 100%;
    }
    & > div {
        width: 70%;
    }
    &.textarea {
        align-items: flex-start;
        .form-label {
            white-space: break-spaces;
        }
        textarea {
            width: 50%;
            height: 15rem;
        }
    }
    &.start {
        align-items: flex-start;
        justify-content: flex-start;
        .form-label {
            white-space: break-spaces;
        }
    }
    &.end {
        justify-content: flex-end;
    }
}

.form-label, .form-legend {
    @include font-family-custom();
    @include font-style(1em, 900, $primary-color-1);
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: .5rem;
    white-space: nowrap;
    
    #adsBehaviourByBrand_template & {
        white-space: unset;
        flex:1;
    }

    &.full {
        flex: 1 auto;
        margin-bottom: .5rem;
    }
    &.fix {
        flex: 1 20%;
    }
    &.wrap {
        white-space: normal;
    }
}

.form-legend {
    margin-top: .5rem;
    margin-bottom: 1rem;
    &.margin-b {
        margin-bottom: 1rem;
    }
    &.off-style {
        color: fade-out($primary-color-1, .45) ;
        text-transform: capitalize;
        display: block;
        position: relative;
    }
    &.is-activated {
        text-transform: uppercase;
        font-size: 1.2rem;
        color: $accent-2;
    }
}

.input-text, .input-pass, textarea {
    background: $input-bg;
    @include font-family-custom();
    @include font-style(1.2em, 400, $primary-color-1);
    color:  $primary-color-1;
    @include size(100%, 3rem);
    box-sizing: border-box;
    padding: 0 0 .125rem 1rem;
    border: 1px solid transparent;
    letter-spacing: .05em;
    display: block;
    box-shadow: none;
    border-radius: $corner;
    &:focus {
        //background: $primary-color-5 !important;
        background: lighten($input-bg, 5%);
        border: 1px solid fade-out($accent-1, .25);
    }
    &.is-error {
        border-color: $accent-1;
    }
    &.num {
        width: 50%;
        text-align: right;
        padding-right: .5rem;
    }
    &.read-only {
        background: rgba(89, 105, 137, .2);
        color: rgba(256, 256, 256, .7);
        pointer-events: none;
    }

}

textarea.input-text {
   height: 10rem;
    &.long {
        height: 40rem;
    }
    &.extra {
        height: 80rem;
    }
}


.is-hidden{
    display: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $input-bg inset;
    //background-color: red !important;
    border: 0;
    -webkit-text-fill-color: $primary-color-1;
}

/*  select */

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* remove default arrow */
    @include size(100%, 3rem);
    border: 0;
    background-color: $input-bg;
    outline: 0;
    @include font-family-custom();
    @include font-style(1.2em, 400, $primary-color-1);
    text-transform: capitalize;
    padding: 0 3rem 0 1rem;
    background-image: url('/assets/images/dropdown-bg.svg');
    background-position: center right 2px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    @include transition();
    @include shadow;
    border-radius: $corner;
    &:hover {

    }
    .w-form & {

    }
    .short {
        width: 10rem;
    }
}

/*  radio button */

input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
    display: none;
}
input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 2.8rem;
    margin-bottom: 0;
    cursor: pointer;
    line-height: 2.5rem;
    display: inline-block;
    pointer-events: auto;
    color: fade-out($primary-color-1, .45);
    text-transform: capitalize;
}
input[type="radio"]:checked + label
{
    color: $primary-color-1;
}
input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: $primary-color-5;
    border: 1px solid fade-out($primary-color-2, .85);
    @include shadow;
}
input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background: $accent-1;
    position: absolute;
    top: .6rem;
    left: .6rem;
    transition: all 0.2s ease;
}
input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
input[type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

/*  checkbox */

input[type="checkbox"] { display: none; }
input[type="checkbox"] + label {
    color: fade-out($primary-color-1, .45);
    text-transform: capitalize;
    display: block;
    position: relative;
    padding-left: 2.6rem;
    margin-bottom: 0;
    line-height: 2.5rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
input[type="checkbox"] + label:last-child { margin-bottom: 0; }

input[type="checkbox"] + label:after {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    background: $primary-color-5;
    border: 1px solid $accent-1;
    border: 0;
    position: absolute;
    left: 0;
    top: .25rem;
    opacity: 1;
    transition: all .12s;
    @include shadow;
}

input[type="checkbox"] + label:before {
    content: "\E914";
    position: absolute;
    color: $accent-1-text;
    left: .45rem;
    top: 1.25rem;
    font-size: 1.25rem;
    z-index: 2;
    opacity: 0;
    transform: scale(0);
    @include transition;
}
input[type="checkbox"]:checked + label {
    color: $primary-color-1;
}
input[type="checkbox"]:checked + label:before {
    opacity: 1;
    transform: scale(1);
}

input[type=checkbox][disabled] + label {
    opacity: .3;
}

hr {
    margin-top: 1rem;
    margin-bottom: -.25rem;
    display: none;
    flex: 1 100%;
    height: 1px;
    background-color: fade-out($accent-1, .5);
    border: 0;
}

.form-hr {
    display: block;
    flex: 1 100%;
    height: 1px;
    background-color: $border;
    border: 0;
    margin-bottom: 2rem;
}


.form-auto-list {
    background-color: $primary-color-5;
    position: absolute;
    top: 4rem;
    z-index: 10;
    @include shadow;
    .form-auto-item {
        @include font-style(1.2em, 400, $primary-color-1);
        padding: .75rem 1rem .75rem 4rem;
        cursor: pointer;
        border-bottom: 1px solid $border;
        display: flex;
        align-items: center;
        background-size: 2rem auto;
        &:hover {
            background-color: $primary-color-4;
        }
    }
    .icon {

    }
}

input[type=checkbox][disabled] + label {
    opacity: .3;
}

input[type="checkbox"].read-only + label:after {
    display: none;
}

input[type="checkbox"].read-only + label {
    display: none;
}

input[type="checkbox"].read-only:checked + label,
input[type="checkbox"].read-only:checked + label:before
{
    pointer-events: none;
    display: block;
    color: $primary-color-1;
}

/* auto fill specific search feild */

.w-spec-search {
    display: flex;
    align-items: center;
    position: relative;
    input, .input-text  {
        @include font-family-custom();
        @include font-style(1.2em, 400, $primary-color-1);
        background-color: fade-out($primary-color-5, .5);
        border: 1px solid fade-out($accent-1, .5);
        @include size(19rem, 3rem);
        padding:2rem 2.5rem;
        outline: 0;
        &:focus {
            border: 1px solid $accent-1;

        }
    }
    &.long {
        input, .input-text  {
            width: 40rem;
        }
    }
    &:after, &:before {
        color: $accent-1;
        position: absolute;
    }
    &:before {
        right: 1rem;
    }
    &:after {
        left: 1rem;
        font-size: 1.25rem;
    }
    &.i-article-a {
        input {
            padding-left: 3rem;
        }
        &:after {
            font-size: 1.5rem;
        }
    }
    .close-btn {
        position: absolute;
        right: .1rem;
        top: 45%;
        transform: translateY(-50%);
        background-color: $primary-color-5;
        padding: .5rem .25rem;
        display: none;
        &:before {

            font-size: 2rem;
            color: $accent-1;
            @include icon-glow($primary-color-2);
        }
        &:hover {
            color: $accent-1;
        }
        &.is-showing {
            display: inline-block;
        }
        ._ & {
            display: none !important;
        }
    }
}



/* drop zone */

.dropzone {
    display: flex;
    align-items: center;
    justify-items: center;
    position: relative;
    box-sizing: border-box;
    padding: 0 1rem 0 1.5rem;
    @include font-style(1.1rem, 600, $primary-color-1);
    @include font-family-custom();
    color: $primary-color-1;
    background-color: $accent-1;
    box-shadow: none;
    //height: 4rem;
    min-height: 4rem;
    line-height: 2;
    border: 0;
    .dz-message {
        display: none;
    }
    &:after {
        text-transform: capitalize;
        content: 'Click or drag your file here.';
        display: block;
        @include font-style(1.1rem, 700, $primary-color-1);
        text-align: center;
        /* position: absolute;
         left: 1rem;
         bottom: 1rem;*/
    }
    &:before {
        color: $primary-color-3;
        font-family: 'icomoon';
        content: '\e92c';
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
        font-size: 1.5rem;
    }
    .dz-preview.dz-image-preview {
        background: none;
    }
}

/* specific */

 .prebid {
     margin-bottom: 3rem;

    .form-sub-title {
        text-align: left;
        margin-bottom: 0;
    }
     .form-group {
        &.grid {
            display: grid;
            grid-template-columns: repeat(6,1fr);
            margin-bottom: 4rem;
        }
     }
     .form-check-inline {
        width: auto;
         flex: 20% auto;
         text-align: left;
     }
 }

 #adsSetupZoneSettingsEdit_template {
      &.w-form {
          width: 63rem;
      }
      select {
          width: auto;
      }
      .input-text + .form-label, .input-text + select {
          margin-left: 1rem;
      }
      .num {
          width: 6rem;
      }
   }


 .templateTypeDependencies  {
     width: calc(100% - 11rem) !important;
     margin-left: 1rem;
 }


 .sspConfig_edit {
     .i-add {
         //position: absolute;
         left: 2rem;
         top: 8rem;
     }
     .form-header {
         position: relative;
         & > div {
             display: flex;
             .form-title {
                 margin-right: 1rem;
             }
         }
     }
     .w-form-body {
         flex-wrap: wrap;
        //padding-top: 7rem;
     }
     .w-tooltips {
         margin-top: 0;
     }
     .tooltips {
         background-color: $primary-color-3;
         left: 1rem;
         &:before {
             border-color: transparent $primary-color-3 transparent transparent;
         }
     }
     @keyframes fadeInAnimation {
         0% {
             opacity: 0;
             
         }
         100% {
             opacity: 1;
         }
     }
     .form-body {
         flex: 0 47.5%;
         width: auto;
         border-left: 0 !important;
         animation: fadeInAnimation ease .65s;
         animation-iteration-count: 1;
         animation-fill-mode: forwards;
         &.padding-top {
             padding-top: 6.5rem;
         }
     }
     .form-hr {
         background-color: transparent;
         margin-bottom: 0;
     }
     .no-margin {
         margin-bottom: 0;
     }
     .start {
         .btn {
             &:first-of-type {
                 margin-left: 0;
             }
         }
     }
     .w-preview {
         padding: 1.5rem 2rem 2rem 2rem;
         //margin-right: 1rem;
         //margin-bottom: 1rem;
         background-color: $primary-color-5;
         border: 1px solid $accent-1;
         @include shadow;
         border-radius: $corner;
         pre {
             @include font-family-code();
             @include font-style(1.1rem, 400, $accent-1-text);
             white-space: pre-wrap;
             word-wrap: break-word;

         }
         span {
             color: $primary-color-1;
         }
     }
 }

.ads_setup_edit {
    .form-group.row {
        .row {
            margin-right: 6rem;
            margin-bottom: 0;
            width: auto !important;
            flex: 1 100%;
        }
        .right {
            flex: none;
            width: auto;
        }
        .form-label {

        }
    }
    .adding {
        select {
            width: 90%;
        }
    }
}


 .ssps {
     .btn {
         margin-left: 1rem;
         margin-right: 0rem;
     }
    .form-group.adding {
        flex-wrap: wrap;
        .border {
            margin-bottom: 1px solid fade-out($accent-1, .5);
        }
        select {
            width: 10rem;
            margin-left: 1rem;
        }
        .input-text {
            width: auto;
            flex: 1 auto;
        }
        .btn {
            flex: none;
        }
        .form-label {
            flex: 1 100%;
        }
        .btb {
            margin-right: 0;
        }
        &:last-of-type {
            hr {
                display: none;
                &:last-of-type {
                    display: block;
                }
            }
        }
    }
 }

.element {
    .form-group {
        margin: 1px 1rem 2rem;
    }
    .form-label {
        margin-right: 1rem;
        &:last-of-type{
            margin-right: 0;
        }
    }
}

 /* Chrome, Safari, Edge, Opera */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }

 /* Firefox */
 input[type=number] {
     -moz-appearance: textfield;
 }