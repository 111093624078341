*[data-content="content"]:before {
    content: "\e96b";
}
*[data-content="sidebar"]:before {
    content: "\e96f";
}
*[data-content="footer"]:before {
    content: "\e969";
}
*[data-content="header"]:before {
    content: "\e96a";
}


.w-ads-unit-page {
    //width: 100%;
    margin: 0 2rem 0rem 2rem;
    text-align: left;
    @include desktop-medium {
        //width: 60%;
    }
    &.is-fixed {
        position: fixed;
    }
    .zone-list {

    }
    .btn-list {
        @include font-family-custom();
        @include font-style(1.2em, 900, $primary-color-1);
        background: fade-out($accent-2, .75);
        color: $accent-2;
        @include size(auto, auto);
        font-size: 1rem;
        padding: .25rem .5rem;
        text-transform: uppercase;
        display: flex;
        border-radius: $corner;
        align-items: center;
        &:before {
            color: $accent-2;
            margin-right: .5rem;
        }
        &:hover {
            color: $primary-color-1;
            &:before {
                color: $primary-color-1;
            }
        }
        &.alert {
            color: $secondary-3;
            background: fade-out($secondary-3, .75);
            &:before {
                color: $secondary-3;
            }
            &:hover {
                color: $primary-color-1;
                &:before {
                    color: $primary-color-1;
                }
            }
        }
        &.secondary {
            color: lighten($accent-1, 40%);
            background: fade-out($accent-1, .65);
            &:before {
                color: lighten($accent-1, 40%);
            }
            &:hover {
                color: $primary-color-1;
                &:before {
                    color: $primary-color-1;
                }
            }
        }
    }
    .zone-list-group {
        margin-top: 3rem;
        padding-left: .5rem;
        height: 3rem;
        display: flex;
        align-items: center;
        background-color: darken($primary-color-3, 2.5%);
        &:first-of-type {
            margin-top: 0;
        }
        strong {
            @include font-style(1.2rem, 900, $accent-2);
            text-transform: uppercase;
            display: flex;
            align-items: center;
            &:before {
                font-size: 2rem;
                margin-right: .5rem;
            }
        }
        /*
        strong[data-content="content"]:before {
            content: "\e96b";
        }
        strong[data-content="sidebar"]:before {
            content: "\e96f";
        }
        strong[data-content="footer"]:before {
            content: "\e969";
        }
        strong[data-content="header"]:before {
            content: "\e96a";
        }

         */
    }
    .zone-list-header {
        background-color: darken($primary-color-3, 2.5%);
        padding: 2.25rem 3rem 2rem 2rem;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: $corner;
        //margin-bottom: -3rem;
        & > div {
            display: flex;

        }
        h3 {
            @include font-style(2.6rem, 100, $accent-2);
            margin-bottom: .75rem;
            margin-right: 2rem;
            min-width: 10rem;
        }

        .btn {
            display: flex;
            margin-right: 1rem;
            @include size(18rem, 3rem);
            padding: 0 2rem 0 1rem;
            transition: all .25s;
            span {
                flex: 1 auto;

            }
            &:before {

            }
            &.i-edit {
                background-color: fade-out($accent-1, .9) !important;
                border: 1px solid $accent-1;
                &:before {
                    color: $accent-1-text;
                }
                &:hover {
                    background-color: $accent-1 !important;
                    &:before {
                        color: $primary-color-1;
                    }
                }
            }
            &.alert {
                background-color: fade-out($secondary-3, .9) !important;
                border: 1px solid $secondary-3;
                &:before {
                    color: $secondary-3;
                }
                &:hover {
                    background-color: $secondary-3 !important;
                    &:before {
                        color: $primary-color-1;
                    }
                }
            }
            &.approved {
                background-color: fade-out($secondary-2, .9) !important;
                border: 1px solid $secondary-2;
                &:before {
                    color: $secondary-2;
                }
                &:hover {
                    background-color: $secondary-2 !important;
                    &:before {
                        color: $primary-color-1;
                    }
                }
            }
        }
    }
    .zone-item {
        border-top: 1px solid transparent;
        overflow: hidden;
        box-sizing: border-box;
        & > div {
            max-height: 3rem;
            min-height: 3rem;
            height: auto;
            transition: .25s max-height;
        }
        &.is-open {
            overflow: auto;
            & > div {
                max-height: 200rem;
            }
            .i-dropdown-simple {
                &:after {
                    transform: rotate(0);
                }
            }
        }
        &.zone-item-repeatable {
            .zone-item-header-title {
                &:after {
                    content: "\e96c";
                    color: $accent-2;
                    font-size: 1.3em;
                    margin-left: .25rem;
                }
            }
            & > div {
                min-height: 6rem;
            }
            &.is-option-open {
                & > div {
                    min-height: 22rem;
                }
                .zone-item-header-option {
                    height: 20rem;
                }
                .btn-list {
                    &.i-dropdown-simple {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }
                .edit {
                    height: 16rem;
                }
            }
        }
        &.is-child {
            @extend .icon;
            position: relative;
            //margin-left: 3rem;
            padding-left: 3rem;
            &:before {
                position: absolute;
                left: 1rem;
                top: 1.5rem;
                content: "\e96d";
                color: $accent-2;
                display: inline-block;
            }
            & > div {
                min-height: 3rem;
            }
        }
    }
    .zone-item-header-option {

        display: flex;
        flex-direction: column;

        margin-left: 3rem;
        background-color: fade-out($primary-color-5, .55);
        .btn-list {
            margin-right: .5rem;
            &.i-dropdown-simple {
                &:after {
                    @include transition;
                    font-size: 1.75rem;
                }
            }
        }
        .preview {
            display: flex;
            align-items: center;
            height: 3rem;
            padding-left: 1rem;
        }
        .edit {
            //flex: 1;
            height: 0;
            overflow: hidden;
            box-sizing: border-box;
            padding: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-top: 1px solid $border-light;
            background-color: darken($primary-color-3, 2.5%);
            @include transition;
            .form-sentence {
                height: 2rem;
                padding: .25rem 0;
                margin-left: 2rem;
                .form-sentence {
                    margin-left: 0;
                }
            }
        }
        .form-sentence {
            display: flex;
            flex-direction: row;
            align-items: center;
            &.fix-width {
                width: 14rem;
            }
            input[type="checkbox"]+label:before {
                top: 0.65rem;
                font-size: 1.125rem;
            }
            &:first-of-type {

            }
            & > .form-label {
                &:first-of-type {
                    //width: 8rem !important;
                }
            }
            .form-label {
                margin: .25rem 1rem 0 0;
                &.fix-width {
                    text-align: right;
                    width: 7rem;
                }
            }
        }
        .form-group {
            margin-bottom: .75rem;
        }
        .form-check-inline {
            width: auto;
            display: flex;
            align-items: center;
        }
        p {
            @include font-style(1.1rem, 900, $primary-color-1);
            margin: 0 .25rem;
            span {
                color: lighten($accent-1, 20%);
                margin: 0 .25rem;
            }
        }
        .input-text {
            height: 2rem;
            margin-right: 1rem;
            &.num {
                width:  5rem;
            }
        }
        select {
            width: auto;
            height: 2rem;
            margin-right: 1rem;
        }
        input[type="checkbox"]+label:after {
            border: 2px solid $input-bg;
            box-sizing: border-box;
            border-radius: $corner;
            top: -0.35rem;
        }
        input[type="checkbox"]+label {
            text-transform: uppercase;
            line-height: normal;
        }
    }
    .zone-item-header {
        display: flex;
        align-items: center;
        height: 3rem;
        padding-right: 1rem;
        background-color: fade-out($primary-color-5, .7);
        .i-dropdown-simple {
            justify-content: flex-end;
            text-align: left;
            padding: 0 .5rem;

            &:after {
                transition: .25s all;
                font-size: 2rem;
                transform: rotate(-.25turn);

            }
        }
        .btn-list {
            &.i-child {
                margin-left: .5rem;
            }
        }
        .spacer {
            flex: 1;
        }
        .input-text {
            height: 2rem;
            margin-right: 3rem;
            &.num {
                width:  3rem;
            }
        }
        .form-label {
            margin: 0;
            &.i-position {
                font-size: 1.2rem;
                margin-right: .25rem;
            }
        }
        .zone-item-header-title {
            @include font-style(1.2rem, 500, $primary-color-1);
            width: 21rem;
            display: inline-block;
            @extend .icon;
        }
        & > div {
            display: flex;
            align-items: center;
        }
        & > span {
            @include font-style(1rem, 700, $accent-1-text);
            text-transform: uppercase;
            width: 7rem;
            display: inline-block;
        }
        .i-add {
            margin-left: .65rem;
        }
        select {
            font-size: 1.1em;
            font-weight: 600;
            width: auto;
            height: 1.8rem;
            margin-left: 0.5rem;
            color: #d3e2ff;
        }

    }
}

.ads-unit-list {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    padding: 2rem 0 2rem 3rem;
    background-color: fade-out($primary-color-5, .9);
    overflow-x: scroll;
    .ads-unit-item {
        position: relative;
        //border: 1px solid $accent-1;
        background-color: lighten($primary-color-4, 6%);
        width: 55rem;
        margin: 0 3rem 0 6rem;
        flex: none;
    }

}

.ads-unit-item {
    &.is-deactivated {
        border-color: transparent;
        .w-ads-unit-body, .w-ads-unit-footer, .ads-unit-header, .w-ads-unit-header-sub, .ads-unit-header .form-group.row div .form-label,
        .ads-unit-side .input-text, .i-copy, .i-delete {
            opacity: .25;
            pointer-events: none;
        }
        .w-ads-unit-header {
            background: desaturate($accent-1, 50%);
        }
        .i-on-off {
            &:before {
                color: fade-out($primary-color-1, .5);
                text-shadow: none;
            }
        }
        .ads-unit-side {
            border-color: transparent;
        }
    }
    &.is-deleted {
        border-color: transparent;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: fade-out($primary-color-5, .25);
            z-index: 4;
            pointer-events: none;
        }
        &:after {
            content: 'This unit will be DELETED on your next SAVE';
            @include font-family-custom();
            @include font-style(2rem, 900, $accent-2);
            text-align: center;
            position: absolute;
            top: 5rem;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
        }
        .ads-unit-side .input-text, .i-copy, .i-on-off {
            opacity: .25;
            pointer-events: none;
        }
        .i-delete {
            background-color: $secondary-2;
           &:before {
               content: "\e95d";
           }
            &:hover {
                background-color: lighten($secondary-2, 5%);
            }
        }
    }
}

.w-ads-unit-header-sub {
    display: flex;
    width: 100%;
    .ads-unit-header {
        &:last-of-type {
            margin-left: 3rem;
        }
    }
}

.w-ads-unit-header {
    //background-color: $accent-1;
    background: radial-gradient(circle, #0069cb 0%, #0069cb 80%, darken(#0069cb, 5%) 100%);
    padding: 2rem 1rem 0 1rem;
    display: flex;
    flex-wrap: wrap;
    /* temp might be ib form.scss */
    /* TO BE REFACTOR */
    .input-text {
        width: 40%;
        &.num {
            width: 30%;
            //flex: none;
        }
    }
    .form-label {
        width: 35%;
        margin-right: .5rem;
        &.width {
            width: 8rem;
            margin-left: -1.5rem;
        }
    }
    select {
        width: 65%;
        background-color: lighten($primary-color-3, 10%);
        border: 0;
    }
    .input-text {
        background-color: lighten($primary-color-3, 10%);
    }
    input[type="checkbox"] + label:after {
        background: $primary-color-3;

    }
    input[type="checkbox"] + label:before {
        color: $accent-2;
    }
    input[type="checkbox"]:checked + label.title {
        text-transform: uppercase;
        font-size: 1.2rem;
        color: $accent-2;
    }
    .form-check-inline {
        width: 49%;
        display: inline-block;
        margin-bottom: .5rem;
        &.no-margin {
            margin-bottom: 0;
        }
    }
    .form-label {
        width: 7rem;
        text-align: right;
    }
    select {
        flex: 1 auto;
        width: auto;
    }
    .input-text + select {
        width: 6rem;
        margin-left: 1rem;
        text-transform: lowercase;
    }
    .input-text {
        flex: 1 auto;
    }
    input[type="checkbox"] + label {
        text-align: left;
    }
    .ads-unit-header:not(.full-width) + .ads-unit-header:not(.full-width) {
        //margin-left: 1rem;
    }
    input[type="radio"]:checked+label:after {
        background-color: $accent-2;
    }
    .ads-unit-header {
        flex: 1 44%;
        .form-group {
            margin-right: .75rem;
            margin-left: .75rem;
            &.is-disable {
                opacity: .5;
                pointer-events: none;
            }
        }
        &.sub {
            margin-left: 1rem !important;
            select {
                margin-right: 1.5rem;
            }
            input[type="checkbox"] + label {
                margin-left: 7.5rem;
            }
        }
        .form-group.borders {
            border: 1px solid fade-out($primary-color-3, .75);
            padding: 1rem;
            border-radius: $corner;
        }
        &.full-width {
            margin: 0 -1px 1rem;

            flex: 1 100%;
            &.border-bottom {
                padding-bottom: .5rem;
                border-bottom: 1px solid fade-out($primary-color-3, .75);
            }
            &.border-top {
                padding-top: 1rem;
                border-top: 1px solid fade-out($primary-color-3, .75);
            }
            .form-group {
                margin-right: 1rem;
                margin-left: 1rem;
                &.no-margin {
                    margin-bottom: 0;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.w-ads-unit-body {
    display: flex;
    flex-direction: column;

    padding-top: 2rem;
    .ads-unit-body {
        display: flex;
        flex-wrap: wrap;
        &.full-width {
            .form-group {
                width: 100%;

            }
        }
        .form-group {
            //flex: 1 42%;
            width: 44.5%;
            position: relative;
            margin: 0 2rem 1.75rem;
            &:nth-of-type(odd) {
                margin-right: 1rem;
            }
            &:nth-of-type(even) {
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }
        .input-text {
            background-color: $input-bg;
            width: 100%;
            padding-top: 1.25rem;
            padding-bottom: .5rem;
            padding-left: .75rem;
            &:focus {
                border-color: $accent-2;
            }
            &:disabled {
                background-color: transparent;
            }
        }
        .form-label {
            white-space: break-spaces;
            position: absolute;
            font-size: .9rem;
            width: auto;
            top: -.75rem;
            left: .65rem;
            background-color: $accent-1;
            padding: .25rem .5rem;
            @include shadow;

        }
        ::placeholder {
            color: fade-out($primary-color-1, .35);
            font-style: italic;
        }
    }
    .ads-unit-body-title {
        @include font-style(1.2rem, 700, $accent-2);
        text-transform: uppercase;
        @include icon-glow($accent-2);
        margin-bottom: 1.5rem;
        display: inline-block;
        flex: 1 100%;
        margin-left: 2rem;
    }
    .ads-unit-preview {
        padding: 1.5rem 2rem 2rem 2rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        background-color: $primary-color-5;
        border: 1px solid $accent-1;
        @include shadow;
        border-radius: $corner;
        pre {
            @include font-family-code();
            @include font-style(1.1rem, 400, $accent-1-text);
            white-space: pre-wrap;
            word-wrap: break-word;

        }
        span {
            color: $primary-color-1;
        }
    }

}

.w-ads-unit-footer {
    background-color: lighten($primary-color-4, 3%);
    color: white;
    display: flex;
    padding: 1rem 2rem;
    .footer-item {
        display: flex;
        flex-direction: column;
        flex: 1 30%;
        span {
            display: block;
            width: 6rem;
        }
        &:first-of-type {
            flex: 1 15%;

            span {
                text-align: left;
                width: auto;
            }
        }
        div {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
        }
    }
    span {
        @include font-style(1rem, 900, $accent-1-text);
        text-transform: uppercase;
        text-align: right;
        margin-right: .5rem;
    }
    strong {
        @include font-style(1rem, 400, $primary-color-1);
    }
}

.ads-unit-side {
    //border: 1px solid $accent-1;
    border-right: 0;
    color: white;
    position: absolute;
    width: 6rem;
    top: -1px;
    left: -6.1rem;
    padding: .5rem 0;
    background-color: fade-out($accent-1, .65);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
        background-color: $primary-color-3;
        @include size(4rem,4rem);
        margin: .5rem 0;

        &:before {
            color: $accent-2;
            @include icon-glow($accent-2);
            font-size: 2rem;
        }
        &:hover {
            &:before {
                color: $primary-color-1;
                @include icon-glow($primary-color-1);
            }
        }
        span {
            display: none;
        }
        &:last-of-type {
            background-color: $secondary-3;
            &:before {
                color: $primary-color-1;
                @include icon-glow($primary-color-1);
            }
            &:hover {
                background-color: lighten($secondary-3, 5%);
            }
        }
    }
    .input-text {
        margin: .5rem 0;
        @include size(4rem, 4rem);
        color: $accent-2 !important;
        @include font-style(1.5rem, 900, $accent-2 );
        padding: 0;
        @include icon-glow($accent-2);
        background-color: $primary-color-3 !important;
        text-align: center;

    }
}

/* lightbox */

.zone-activation {
    .form-check-inline {
        width: 20%;
    }
    .form-sub-title{
        border-top: 1px solid fade-out($primary-color-3, .5);
        padding-top: 1.5rem;
        text-align: left;
        margin-left: 2rem;
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        &:before {
            font-size: 2rem;
            margin-right: .5rem;
        }
    }
    .form-group {
        margin-bottom: 2rem;
        &.no-margin {
            margin-bottom: 0;
            p {
                margin-bottom: 0;
            }
        }
    }
}

/* temp */
.form-header {
    margin-top: 10rem;
}