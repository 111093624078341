.w-tooltips-adv {
    width: 1.5rem !important;
    height: 2.5rem !important;
    padding-left: .5rem;
    ::-webkit-scrollbar {
        scrollbar-color: $primary-color-5 $primary-color-5;   /* scroll thumb and track */
        width: 5px;
    }
    *::-webkit-scrollbar-track {
        background: $primary-color-5     /* color of the tracking area */
    }
    *::-webkit-scrollbar-thumb {
        background-color:  $primary-color-3;    /* color of the scroll thumb */
        border-radius: 0px;       /* roundness of the scroll thumb */
        border: 0px solid $primary-color-5;  /* creates padding around scroll thumb */
    }
    &.short {
        .tooltips-adv {
            height: 14rem !important;
            width: 30rem;
        }
    }
    &.pos-right {
        .tooltips-adv {
            top: 0 !important;
            bottom: auto!important;
            left: 3.5rem;
            transform: translateX(0%);
            &:before {
                right: auto;
                left: -0.9rem;
                top: 3.8rem;
                bottom: auto !important;
                transform: rotate(270deg) translateX(3rem);
            }
        }
    }
    &.pos-top {
        .tooltips-adv {
            top: auto !important;
            bottom: 3.5rem !important;
            transform: translateX(50%);
            &:before {
                top: auto !important;
                bottom: -1rem !important;
                transform: rotate(180deg) translateX(3rem);
            }
        }
    }
    &.pos-bottom {
        .tooltips-adv {
            bottom: auto !important;
            top: 3.5rem !important;
            transform: translateX(50%);
            &:before {
                bottom: auto !important;
                top: -1rem !important;
                transform: rotate(0deg) translateX(-3rem);
            }
        }
    }
    .tooltips-adv {
        position: absolute;
        transform: translateX(-13.75rem);
        left: 50%;
        top: 3.5rem;
        @include size(32rem, 19.75rem);
        background-color: lighten($primary-color-4, 12%);
        border: 2px solid lighten($primary-color-4, 25%);
        border-radius: $corner;
        z-index: 30;
        box-shadow: 0 0 12px 0px rgba(0,0,0,0.125) inset,  0px 0px 10px 0px fade-out($primary-color-5, .7);
        padding: 1.5rem .5rem 0 1.5rem;
        text-align: left;
        &:before {
            position: absolute;
            top: -1rem;
            left: 50%;
            transform: translateX(-2.5rem);
            content: '';
            width: 0;
            height: 0;
            border-radius: $corner;
            border-style: solid;
            border-width: 0 7.5px 10px 7.5px;
            border-color: transparent transparent lighten($primary-color-4, 12%) transparent;
            z-index: 19;
        }
        &.pos-right {
            /*
            left: auto;
            right: -50%;
            transform: translateX(0);
            &:before {
                left: auto;
                right: 1.25rem;
                transform: translateX(0);
            }

             */
        }
        &.pos-left {
            left: auto;
            right: -50%;
            transform: translateX(0);
            &:before {
                left: .25rem;
                transform: translateX(0);
            }
        }
    }
    .tooltips-adv-title {
        @include font-family-custom();
        @include font-style(1.4rem, 700, $primary-color-1);
        text-transform: uppercase;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.25);
        margin-bottom: .5rem;
    }
    .tooltips-adv-content {
        height: 75%;
        overflow-y: scroll;
        padding-right: .5rem;
        //white-space: nowrap
    }
    p {
        @include font-family-custom();
        @include font-style(1.2rem, 400, $primary-color-1);
        margin-bottom: .5rem;
    }
    .open-button {
        @include size(2.5rem, 2.5rem);
        border-radius: 50%;
        background-color: lighten($primary-color-4, 12%);
        border: 2px solid lighten($primary-color-4, 25%);
        @include shadow;
        margin-left: .25rem;
        &:before {
            color: $accent-2;
        }
        .list-results & {
            transform: scale(.8);
        }
    }
    .close-button {
        @include size(2.5rem, 2.5rem);
        border-radius: 50%;
        position: absolute;
        top: -1.2rem;
        right: -1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include shadow;
        background-color: lighten($primary-color-4, 12%);
        border: 2px solid lighten($primary-color-4, 25%);
        &:before {
            color: $accent-2;
            font-size: 2.35rem;
        }
        &:hover {
            &:before {
                color: $primary-color-1;
            }
        }
    }
}