/*
LIGHTBOX COMMENTS

TO USE LIGHT OR DARK THEME
Use class 'dark-theme' for light overlay and dark content block
Use class 'light-theme' for dark overlay and light content block

*/

/************************************************************
LIGHTBOX - Default Styles For All Lightboxes
************************************************************/

/* Lightbox Dark Theme (Light overlay w/ dark content block) */
$lightbox-overlay-light: $primary-color-1;
$lightbox-content-dark-bg: $primary-color-5;
$lightbox-content-light-text: $primary-color-1;

/* Lightbox Light Theme (Dark overlay w/ light content block) */
$lightbox-overlay-dark: $primary-color-5;
$lightbox-content-light-bg: $primary-color-1;
$lightbox-content-dark-text: $primary-color-5;


/* Background Overlay */

.w-lightbox {
    opacity: 0;
    visibility: hidden; 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:9999999;
    transition: all .3s ease;

    /* Show the Lightbox */
    &.is-visible {
        opacity: 1;
        visibility: visible; 
    }
    /* Dark Theme (Light overlay w/ dark content */
    &.dark-theme {
        background: fade-out(#000, .25);
    }
    /* Light Theme (Dark overlay w/ light content */
    &.light-theme {
        background: fade-out($lightbox-overlay-dark, .25);
    }
}


/* Lightbox Content Area */

.lightbox-content {
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
    
    /* Dark Theme */
    .dark-theme & {
        //background: $lightbox-content-dark-bg;
        color: $lightbox-content-light-text;
    }
    
    /* Light Theme */
    .light-theme & {
        //background: $lightbox-content-light-bg;
        color: $lightbox-content-dark-text;
    }
    &.large {
        width: 80%;
        @include desktop-medium {
            width: 60%;
        }
    }
    .w-form-large {
        width: 100%;
        .form-body.full-width {
            width: calc(100% - 2rem) !important;
            border: 0;
        }
    }

    .lb-btn-close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        padding: 0;
        font-size: 16px;
        &::before {
            display: block;
            padding: .5rem;
        }
        .dark-theme & {
            color: $lightbox-content-light-text; 
        }
        .light-theme & {
            color: $lightbox-content-dark-text;
        }
    }
}    

