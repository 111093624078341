.dhtmlxcalendar_material div.dhtmlxcalendar_time_cont ul.dhtmlxcalendar_line li.dhtmlxcalendar_time_hdr {
    color: $primary-color-1;
}

.dhtmlxcalendar_material {
    background-color: lighten($primary-color-4, 15%);
    .dhtmlxcalendar_line .dhtmlxcalendar_month_label_month,
    .dhtmlxcalendar_line .dhtmlxcalendar_month_label_year {
        color: $primary-color-1;
    }
    div.dhtmlxcalendar_dates_cont, .dhtmlxcalendar_month_cont, .dhtmlxcalendar_in_input  {
        ul.dhtmlxcalendar_line {
            li.dhtmlxcalendar_month_hdr {
                color: $primary-color-1;
            }
            li.dhtmlxcalendar_cell {
                color: $primary-color-1;
            }
            li.dhtmlxcalendar_cell_month_date {
                background-color: $accent-1;
            }
            li.dhtmlxcalendar_cell_month_weekend {
                color: $accent-1-text;
            }
            li.dhtmlxcalendar_cell_month {
                color: $primary-color-1;
            }
            li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr {
                color: $primary-color-1;
            }
            li.dhtmlxcalendar_cell_month_weekend_hover,
            li.dhtmlxcalendar_cell_month_hover,
            li.dhtmlxcalendar_cell_weekend_hover,
            li.dhtmlxcalendar_cell_hover {
                color: $primary-color-1;
                background-color: $accent-1;
            }
            li.dhtmlxcalendar_cell_weekend {
                color: fade-out($accent-1-text, .5);
            }
            li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr span {
                color: $primary-color-5;
            }
        }
    }
}

.dhtmlxcalendar_material {
    div.dhtmlxcalendar_time_cont.dhtmlxcalendar_mode_time {
        ul.dhtmlxcalendar_line {
            li.dhtmlxcalendar_cell.dhtmlxcalendar_time_hdr {
                span {
                    color: $primary-color-5;
                }
            }
        }
    }
}

.dhtmlxcalendar_material.dhtmlxcalendar_in_input div.dhtmlxcalendar_month_cont,
.dhtmlxcalendar_material.dhtmlxcalendar_in_input div.dhtmlxcalendar_days_cont,
.dhtmlxcalendar_material.dhtmlxcalendar_in_input div.dhtmlxcalendar_dates_cont,
.dhtmlxcalendar_material.dhtmlxcalendar_in_input div.dhtmlxcalendar_time_cont {
    border: 0;
}


.dhtmlxcalendar_material.dhtmlxcalendar_in_input div.dhtmlxcalendar_time_cont {
    border-top: 1px solid $primary-color-1;
}

