/* validation errors */

.w-validation-errors {
    /*
    position: fixed;
    left: calc(50% + 12rem);
    //left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    */
    @include shadow;
    margin: 2rem auto 0;
    width: 50rem;
    box-sizing: border-box;
    border-radius: $corner;
    overflow: hidden;
    z-index: 10;
    &.hide {
        display: none;
    }
    .val-error-header {
        padding: .5rem 2rem;
        display: flex;
        height: 4rem;
        justify-content: space-between;
        background-color: $secondary-3;
        align-items: center;
    }
    .val-error-header-title {
        @include font-family-custom();
        @include font-style(1.2rem, 700, $primary-color-5);
        text-transform: uppercase;
        &:before {
            color: $primary-color-5;
            margin-right: .75rem;
            font-size: 1.75em;
        }
    }
    .val-error-list {
        text-align: left;
        padding: 2rem 2rem 2rem 4rem;
        background-color: $primary-color-3;
    }
    .val-error-item {
        margin-bottom: 1.75rem;
        position: relative;
        &:last-of-type {
            margin-bottom: 0;
        }
        &:before {
            content: '\2022';
            color: $secondary-3;
            font-size: 2rem;
            position: absolute;
            left: -1rem;
            top: .5rem;
        }
    }
    .validation-label {
        display: block;
        @include font-family-custom();
        @include font-style(1.6rem, 700, $secondary-3);
        margin-bottom: .5rem;
    }
    .validation-message {
        @include font-style(1.2rem, 700, $primary-color-1);

        ul {
            margin-top: .5rem;
            margin-left: .5rem;
            font-weight: 400;
            li {
                &:before {
                    content: '-';
                    margin-right: .5rem;
                }
            }
        }
    }
    .close-button {
        font-size: 1.5rem;
        color: $accent-1;
        opacity: 0;
    }
}
