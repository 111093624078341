.zone-notification {
    position: fixed;
    background-color: fade-out($primary-color-5, .15);
    bottom: 12rem;
    left: calc(50% - 20rem);
    z-index: 10;
    width: 40rem;
    min-height: 8rem;
    border-radius: .2rem;
    box-sizing: border-box;
    pointer-events: none;
    //border: 1px solid $primary-color-3;
    display: table;
    @include shadow;
    animation: 3s slidein-fadeout;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    animation-fill-mode: forwards;
    .text {
        display: table-cell;
        vertical-align: middle;
        padding: $site-padding;
        text-align: left;
    }
    strong {
        text-transform: uppercase;
        display: block;
        @include font-family-custom();
        @include font-style(1.4rem, 700, $primary-color-1);
    }
    p {
        @include font-family-custom();
        @include font-style(1.2rem, 400, $primary-color-1);
    }
    &::before {
        //border-right: 1px solid $primary-color-3;
        display: table-cell;
        vertical-align: middle;
        width: 8rem;
        height: 100%;
        font-size: 3rem;
        color: $primary-color-5;
        text-align: center;
    }
    &.success {
        &::before {
            background-color: $success;
        }
    }
    &.alert {
        &::before {
            background-color: $alert;
        }
    }
    &.error {
        &::before {
            background-color: $error;
        }
    }
}