
 /* temp class, should have its own class */
.list-results {
    thead {
        tr.header-0 {
            height: auto;
            padding: 0;
            background: none;
            span {
                height: auto;
                color: $accent-1-text;
            }
            th {
                padding: 1rem 2rem;
                text-align: center;
                @include inner-shadow;
                border: 2px solid fade-out($accent-1, .5);
                box-shadow: inset 0px -1px 20px -1px fade-out($accent-1, .75);
                background-color: fade-out($primary-color-5, .65);
                &:first-of-type {
                    background-color: fade-out($primary-color-5, .5);
                    border: 0;
                    box-shadow: none;
                }
                &.align-left {
                    text-align: left;
                }
            }
        }
    }
    &.ad-health {
        thead {
            border-bottom: 1px solid lighten($primary-color-5, 15%);
            tr {
                th {
                    &:nth-of-type(13n+1) {
                        border-right: 1px solid lighten($primary-color-5, 15%);
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    &:first-of-type {
                        background-color: lighten($primary-color-4, 6%);
                    }
                }
                td {
                    &:nth-of-type(13n+1) {
                        border-right: 1px solid fade-out($primary-color-5, .5);
                    }
                }
            }
        }
    }
}


