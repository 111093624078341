/* Recurent  Styles */

@mixin general-margin {
    margin: 0 $site-padding;
}


@mixin general-padding {
    padding: 0 $site-padding;
}

@mixin transition {
    transition: all .25s;
}

@mixin icon-glow($color) {
    text-shadow: fade-out($color, .75) 1px 0 6px;
}

@mixin shadow {
    box-shadow: 0px 0px 10px 0px fade-out($primary-color-5, .7);
}

@mixin inner-shadow {
    box-shadow: inset 0px 0px 20px 0px fade-out($primary-color-2, .9);
}

@mixin shadow-darker {
    box-shadow: 0px 0px 30px 0px fade-out(#000, .75);
}

@mixin hover {
    &:hover {
        transform: scale(1.05);
    }
}

@mixin gradient-dark {
    background: linear-gradient(10deg, $primary-color-1 0%, $primary-color-1 60%, $primary-color-2 100%);
}

@mixin gradient-light {
    //background: linear-gradient(45deg, rgba(70,197,219,1) 0%, rgba(70,197,219,1) 15%, rgba(194,244,255,1) 100%);
    background: linear-gradient(45deg, #e0faff 0%, #f6fdff 15%, #c2f4ff 100%);
}

@mixin gradient-header-secondary {
    @include gradient-ver(lighten($primary-color-2, 5%), $primary-color-2, $primary-color-2);
}

@mixin line-clamp($line-number) {
    // display: -webkit-box;
    -webkit-line-clamp: $line-number;
    -moz-line-clamp: $line-number;
    // overflow: hidden;
    // text-overflow: ellipsis;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    /* autoprefixer: on */
}


/* font */

//@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');
//@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Source+Code+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

@mixin font-family-default() {
    font-family: 'Helvetica', 'Arial', sans-serif;
}

/* Font Weights: 400, 700 */

@mixin font-family-code() {
    //font-family: 'Noto Sans', sans-serif;
    font-family: 'Source Code Pro', monospace;
}

@mixin font-family-custom() {
    //font-family: 'Noto Sans', sans-serif;
    font-family: 'Quicksand', sans-serif;
    //font-family: 'Didact Gothic', sans-serif;
}

@mixin font-family-universal() {
    font-family: 'Roboto Condensed', sans-serif;
}


/* Mixin - Font Style */
@mixin font-style($size, $weight, $color) {
    font-size: $size;
    font-weight: $weight;
    color: $color;
}

/* Mixin - Web safe Font */
@mixin websafe-font() {
    font-family: helvetica, arial, tahoma, sans-serif;
}

/* fixed aspect ratio */
@mixin aspect-ratio($width, $height) {
    position: relative;
    &::before {
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .ratio-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@keyframes slidein-fadeout {
    0% {
        bottom: -32rem;
    }
    7.5% {
        bottom: 12rem;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes flash-anim {
    0% {
        background-color: fade-out($section-3, 0);
        transform: scale(1.25);
    }
    80% {
        background-color: fade-out($section-3, 0);
        transform: scale(1.25);
    }
    100% {
        background-color: fade-out($section-3, 1);
        transform: scale(1);
    }
}


/* Gradient */
@mixin gradient-ver($top-color, $bottom-color, $fall-back) {
    background-color: $fall-back;
    background: -webkit-linear-gradient(top, $top-color 0%, $bottom-color);
    background: linear-gradient(to bottom, $top-color 0%, $bottom-color);
}
@mixin gradient-hor($left-color, $right-color, $fall-back) {
    background-color: $fall-back;
    background: -webkit-linear-gradient(left, $left-color 0%, $right-color);
    background: linear-gradient(to right, $left-color 0%, $right-color);
}

/* Mixin - Line Clampin' (Cut text that is on multiple lines) */
@mixin line-clamp($line-number) {
    display: -webkit-box;
    -webkit-line-clamp: $line-number;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Mixin - Truncate String (cut text that is on 1 line) */
@mixin truncate-string() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

@mixin size($width, $height) {
    width: $width;
    height: $height;
}



/************************************************************
MEDIA QUERIES
************************************************************/

/** Target points **/
$mobile: 375px;
$tablet: 768px;
$desktop: 1024px;
$desktop-medium: 1240px;
$desktop-large: 1440px;



/* MOBILE - Targeting iPhone 5s only */
@mixin mobile-small {
    @media (max-width: #{$mobile - 1px}) {
        @content;
    }
}

/* TABLET - Tablet & up */
@mixin mobile {
    @media (min-width: #{$mobile}) {
        @content;
    }
}


/* MOBILE - Only Mobile */
@mixin mobile-max {
    @media (max-width: #{$tablet - 1px}) {
        @content;
    }
}

/* TABLET - Tablet & up */
@mixin tablet {
    @media (min-width: #{$tablet}) {
        @content;
    }
}

/* TABLET - Targeting only tablet */
@mixin tablet-only {
    @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
        @content;
    }
}

/* TABLET - Targeting Mobile & Tablet */
@mixin tablet-max {
    @media (max-width: #{$desktop - 1px}) {
        @content;
    }
}

/* DESKTOP - Desktop & Up */
@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}

/* DESKTOP - Custom targeting point */
@mixin desktop-medium {
    @media (min-width: #{$desktop-medium}) {
        @content;
    }
}

/* DESKTOP -  13 inch Macbook Pro screen */
@mixin desktop-large {
    @media (min-width: #{$desktop-large}) {
        @content;
    }
}

/* Animations mixins */

/* Animation Keyframes */
@mixin keyframes($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }
}

/* Animation values */
@mixin animation($str) {
  animation: #{$str};
}

/* Bouncing */
@include keyframes(bounce) {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-4px);
  }
}