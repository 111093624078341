.user-profil {
    @include gradient-ver(lighten($primary-color-3, 7%), lighten($primary-color-3, 10%), $primary-color-3);
    margin: 2rem auto 10rem;
    @include desktop-medium {
        width: 40%;
    }
    @include shadow();
    text-align: centgiter;
    width: auto;
    border-radius: $corner;
    overflow: hidden;
    z-index: 0;
    position: relative;
    .user-header {
        padding-top: 3rem;
        @include gradient-ver(lighten($primary-color-3, 1%), lighten($primary-color-3, 4%), $primary-color-3);
        @include shadow();
        padding-bottom: 3rem;
        .w-user-img {
            position: relative;
            @include size(175px, 175px);
            border-radius: 50%;
            overflow: hidden;
            @include shadow();
            border: 6px solid $accent-1;
            margin: 0 auto 2rem;
        }
        .user-img  {
            @include size(auto, 175px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        strong {
            display: block;
            @include font-family-custom();
            @include font-style(1.75rem, 400, $primary-color-1);
            margin-bottom: .5rem;
        }
        .brand-list {
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 1rem auto;
            padding: 2rem;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                margin: .5rem auto 1rem;
                width: 5rem;
                height: 1px;
                background-color: $accent-1;
            }
        }
        .brand-item {
            color: $primary-color-4;
            display: inline-block;
            font-size: 2.5rem;
            margin: 0 .25rem;
            @include icon-glow($accent-1);
        }
        .btn {
            position: absolute;
            top: .75rem;
            display: block;
        }
        .i-edit {
            right: 1rem;
        }

        .i-back {
            left: 1rem;
            &:before {
                font-size: 2.25rem;
                line-height: 1.25;
            }
        }
    }
    .user-infos {
        box-shadow: none;
        padding: 0;
        margin: 0;
    }
    .user-infos-list {
        border: 0;
        margin: 3rem 2rem 2rem;
    }
    .user-infos-item {
        text-align: left;
        padding-bottom: .5rem;
        border: 0;
        border-bottom: 1px dashed fade-out($primary-color-1, .85);
        margin-bottom: 2rem;
    }
    .user-infos-title {
        @include font-style(1rem, 700, $primary-color-1);
        display: block;
    }
    .user-infos-value {
        @include font-style(1.2rem, 400, $primary-color-1);
    }
}

