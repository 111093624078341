/* date picker box */

/* dropdown */
.w-time-period {
    position: relative;
    .dropdown {
        .dropdown-toggle {
            background-image: url(/assets/images/time-period-red.svg);
            padding-right: 4rem;
            padding: 0 5rem 0 1rem;
            color: lighten($accent-1-text, 10%);
            font-size: .9rem;
            text-transform: uppercase;
            display: none;
            &.is-visible {
                display: block;
            }
        }
        .time-period-header {
            display: flex;
            background-color: lighten($primary-color-4, 3%);
            border-radius: $corner $corner 0 0;
            padding-bottom: .5rem;
            margin: 1rem 0 0 0;
        }
        .time-period-body {
            padding: 1rem;
            display: none;
            select {
                width: 17rem;
                height: 3rem;
                box-shadow: none;
                background-color: $accent-1;
                color: $primary-color-1;
                padding: 0 0 0 1rem;
                margin-bottom: -2rem;
            }
            &.is-selected {
                display: block;
            }
            .label-preview {
                @include font-family-custom();
                @include font-style(1em, 700, lighten($primary-color-3, 40%));
                text-transform: uppercase;
                display: inline-block;
                margin: .25rem 0 .5rem .25rem;
            }
        }
        .dropdown-period {
            position: absolute;
            top: calc(3.5rem - 1px);
            min-width: 10rem;
            flex-direction: column;
            z-index: 600;
            animation: .15s linear fade-in;
            animation-fill-mode: forwards;
            display: none;
            border-radius: $corner;
            background-color: lighten($primary-color-5, 10%);
            display: none;
            left: 0;
            top: 4rem;
            width: 100%;
            &.is-visible {
                display: flex;
            }

        }
        .label-cal {
            @include font-family-custom();
            @include font-style(1.2em, 700, $primary-color-1);
            background-color: lighten($primary-color-4, 15%);
            border-radius: $corner $corner 0 0;
            text-transform: capitalize;
            display: inline-block;
            padding: 0.5rem 1rem;
            transform: translate(0, 31px);
        }
        .dhtmlxcalendar_material {
            position: static;
            transform: scale(0.8);
            margin: 0 -3.5rem;
            box-shadow: none;
            div {
                & > div {
                    border: 0;
                }
            }
        }
        .label-range {
            @include font-family-custom();
            @include font-style(1.2em, 700, $primary-color-1);
            text-transform: uppercase;
            display: inline-block;
            margin: 1rem 0;
        }
        .spacer {
            margin: -1rem 0 -3rem;
            opacity: 0;
        }
        input::placeholder {
            opacity: 0.5;
            color: $primary-color-1;
        }
        .time-period-footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            padding: 1rem;
            background-color: lighten($primary-color-4, 5%);
            border: 1px solid $primary-color-4;
            border-radius: 0 0 $corner $corner;
            .btn {
                box-shadow: none;
                margin: 0;
                height: 3rem;
                background-color: $accent-1;
                color: $primary-color-1;
                padding: 0;
                text-align: center;
                justify-content: center;
                cursor: pointer;
            }
        }
        .w-selection {
            @include font-family-custom();
            @include size(15rem, 4rem);
            background-size: 40px 40px;
            background-position: center right;
            background-repeat: no-repeat;
            background-color: fade-out($accent-1, .5);
            padding: 0 3rem 0 1rem;
            border-radius: $corner;
            text-transform: capitalize;
            text-align: left;
            position: relative;
            display: none;
            margin-bottom: 0rem;
            &.is-visible {
                display: block;
            }
            &:hover {
                &:after {
                    color: $primary-color-5;
                }
            }
            &:hover {
                color: lighten($accent-1-text, 25%);
            }
            .btn-period-edit {
                text-align: left;
                &:hover span {
                    color: $primary-color-1 !important;
                }
                label, span {

                }
            }
            .btn-period-clear {
                &:after {
                    color: $accent-1;
                    font-size: 3.5rem;
                    display: block;
                    position: absolute;
                    right: .25rem;
                    top: 50%;
                }
                &:hover {
                    &:after {
                        color: $primary-color-1;
                    }
                }
            }
            label {
                position: static;
                margin: 0;
                font-size: .9rem;
            }
            .clear-top-label {
                @include font-style(1rem, 700, lighten($primary-color-3, 55%));
            }
            .w-clear-text {
                display: grid;
                grid-template-columns: 3rem auto;
                @include font-style(1rem, 700, $primary-color-1);
            }
        }
    }
}

.w-date-picker {
    display: flex;
    align-items: center;
    border: 1px solid fade-out($accent-1, .75);

    .dropdown-toggle, .date-picker  {
        box-shadow: none;
        margin-right: 0;
        @include size(auto, 4rem);
    }
    .date-picker {
        @include size(13.5rem, 4rem);
        background-color: fade-out($primary-color-5, .5);
        box-sizing: border-box;
        position: relative;
        border: 0;
        //border-right: 1px solid $primary-color-1;
        &:first-of-type {
            border-radius: $corner 0 0 $corner;

        }
        &:last-of-type {
            border-left: 0;
        }
        &:after {
            color: $accent-1;
            background-color: $primary-color-5;
            position: absolute;
            right: 0;
            top: 0;
            @include size(3.8rem, 3.8rem);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
            pointer-events: none;
        }
        &:hover {
            border-color: $primary-color-1;
        }
        label {
            display: block;
            text-transform: uppercase;
            margin-right: .5rem;
            text-align: left;
        }
        input {
            //position: absolute;

            padding: 2rem 3rem 0 1rem;
            @include font-family-custom();
            @include font-style(1.2rem, 700, $primary-color-1);
            border: 0;
            background: none;
            margin: 0;

            cursor: pointer;
        }
    }
    .dropdown-toggle {
        padding: 0 .5rem 0 1rem !important;
        margin-bottom: 0 !important;
        width: 11.8rem;
        background-image: url('/assets/images/dropdown-bg.svg') !important;
        background-size: 24px 24px !important;
        background-position: 95% center !important;
        border-radius: 0 $corner $corner 0;
        border: 0;
        &:before {
            color: $accent-1;
            margin-right: .5rem;
            font-size: 1.5rem;
        }
        &:hover {
            border-color: $accent-2;
        }
    }
    .dropdown-link {
        @include font-family-custom();
        @include font-style(1.2rem, 400, $primary-color-1);
        background-color: lighten($primary-color-4, 15%);
        padding: .5rem  1rem;
        &:hover {
            color: $accent-1-text;
            background-color: lighten($primary-color-4, 13%);
        }
    }
    .dropdown-list {
        width: 12.8rem;
        min-width: 0 !important;

    }
}

.ww-date-picker {
    & > .form-label {
        &:before {
            margin-right: .25rem;
        }
    }
}

/*--- Date picker single ---*/

.single { /* w-date-picker */
    .date-picker {
        @include size(19.5rem, 4rem);
        input {
            width: 100%;
        }
    }

}


.w-form {
    .w-date-picker {
        border: 0;
    }
    .date-picker {
        width: 15.5rem;
        background: $primary-color-4;
        border-radius: $corner !important;
        &:after {
            background-color: $primary-color-5;
            border-radius: $corner;
        }
        input {
            width: 100%;
            padding: 1.25rem 3rem 0 1rem;
        }
    }
}



