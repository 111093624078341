.sub-header {
    .dropdown-list {
        /*
        display: block !important;
        opacity: 1 !important;
        visibility: visible !important;
         */
        min-width: 0 !important;
        width: 19rem !important;
        left: 0 !important;
    }

}

.w-multi-dropdown {
    width: 19rem !important;
    background-color: $primary-color-5;

    .adsBehaviourByBrand & {
        width: min-content !important;
    }
    .w-multi-dropdown-header {
        position: absolute;
        width: calc(100% - 0rem);
        top: 0;
        z-index: 30;
        ._tasks & {
            position: static;
        }
    }
    .multi-dropdown-header {
        display: flex;
        grid-gap: 1rem;
        margin: 0;
        padding: 1rem;
        box-sizing: border-box;
        background-color: $primary-color-3;
        border-bottom: 1px solid $border;
        .adsBehaviourByBrand & {
            width: 31rem !important;
        }
        .btn {
            @include font-style(1em, 700, $primary-color-1);
            background-color: $accent-1;
            box-shadow: none;
            display: flex;
            justify-content: center;
            flex: 1 100%;
            text-align: center;
            height: auto;
            padding: .5rem 0;
            margin: 0;
            &:hover {
                background-color: lighten($accent-1, 5%);
            }
        }
        select {
            background-image: url('/assets/images/dropdown.svg');
            background-position: center right 7.5px;
            background-repeat: no-repeat;
            background-size: 10px 10px;
            @include font-style(1em, 700, $primary-color-2);
            background-color: fade-out($primary-color-2, .85);
            height: auto;
            padding: 0.5rem;
            border: 0;
        }
    }
    .multi-dropdown-header + .multi-dropdown-header {
        position: static;
    }
    .multi-dropdown-body {
        padding: 1rem;
        padding-top: 6rem;
        max-height: 20rem;
        overflow-y: auto;
        overflow-x: hidden;
        //background-color: crimson;
        //overflow-x: visible;
        &::-webkit-scrollbar {
            width: 4px;
        }
        &.is-multi {
            padding-top: 10rem;
        }
        ._tasks & {
            padding-top: 1rem;
        }
    }
    .multi-dropdown-list {
        .multi-dropdown-list {
            margin-left: 3.5rem;
            margin-top: .75rem;
        }
        .w-tooltips-adv {
            margin-top: -0.125rem;
        }
    }
    .multi-dropdown-collapse {
        height: 0;
        visibility: hidden;
        display: none;
        //overflow: hidden;
        //width: 16rem;
    }
    .multi-dropdown-item {
        margin-bottom: .25rem;
        &.is-open {
            .multi-dropdown-collapse {
                height: auto;
                display: block;
                visibility: visible;
                //overflow-x: auto;
                //overflow-y: visible;
            }
            .btn-collapse {
                &:before {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .w-input {
        display: flex;
        align-items: flex-start;
        label {
            word-break: break-word;
            flex: 1;
        }
    }
    .btn-collapse {
        margin-right: .25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include size(1.25rem,1.25rem);
        &:before {
            font-size: 2rem;
            color: $primary-color-2;
        }
    }
    .w-search {
        position: relative;
        &:before {
            left: 0.5rem;
            top: calc(50%);
            transform: translateY(-50%);
            position: absolute;
            color: $primary-color-3;
        }
    }
    .multi-dropdown-search {
        width: calc(100% + 1.25rem);
        box-sizing: border-box;
        border: 1px solid lighten($primary-color-3, 20%);
        height: 2.2rem;
        padding: 0 .5rem 0 2.5rem;
        @include font-family-custom();
        @include font-style(1.1em, 700, $primary-color-2);
        &:focus {
            border: 1px solid $primary-color-3;
        }
    }
    input[type="checkbox"] + label,
    input[type="radio"] + label {
        @include font-family-custom();
        @include font-style(1.1em, 400, $primary-color-1 );
        text-transform: uppercase;
        text-align: left;
        padding-left: 2rem;
        padding-top: .125rem;
        margin: 0;
        line-height: normal;
        @include truncate-string();
        &:before {
            //color: red;
        }
        .ex-group-name {
            display: block;
            color: lighten($primary-color-3, 7%);
            text-transform: capitalize;
        }
    }
    input[type="checkbox"]:checked + label,
    input[type="radio"]:checked + label {
        color: $accent-2;
    }
    input[type="checkbox"]:checked + label:after,
    input[type="checkbox"]:not(:checked) + label:after,
    input[type="radio"]:checked + label:after,
    input[type="radio"]:not(:checked) + label:after {
        width: 1.125rem;
        height: 1.125rem;
        top: .125rem;
        border: 1px solid fade-out($primary-color-1, .5);
    }
    input[type="checkbox"]:checked + label:before,
    input[type="checkbox"]:not(:checked) + label:before,
    input[type="radio"]:checked + label:before,
    input[type="radio"]:not(:checked) + label:before {
        left: 0.25rem;
        top: 0.85rem;
        font-size: .8rem;
        color: $accent-2;
    }
    input[type="radio"]:checked + label:before,
    input[type="radio"]:not(:checked) + label:before {
        top: 0.45rem;
        width: 0.75rem;
        height: 0.75rem;
        border: 1px solid  $primary-color-1;
    }
    input[type="radio"]:checked + label:after,
    input[type="radio"]:not(:checked) + label:after {
        top: 0.65rem;
        width: 0.5rem;
        height: 0.5rem;
        background-color: $accent-2;
        left: 0.5rem;
        border: 0;
    }
}

/* special class for filter dropdownm */

.multi-dropdown-item {
    .w-tooltips-adv,.w-tooltips-adv {
        height: 1rem;
    }
    .open-button {
        transform: translateY(-3px);
    }
    .tooltips-adv-content {
        @include font-family-custom();
        @include font-style(1.2rem, 400, $primary-color-5);
        margin-bottom: .5rem;
        height: 90%;
    }
    .tooltips-adv.tooltips-adv {
        position: absolute;
        top: calc(-50% - .5rem) !important;
        right: auto !important;
        left: auto !important;
        bottom: auto!important;
        height: 5rem !important;
        width: 13rem !important;
        transform: translate(-124px, -50%);
        padding-bottom: 1rem;
        &:before {
            /*
            right: 0.9rem;
            left: auto;
            top: auto;
            bottom: -0.8rem !important;
            transform: rotate(180deg) translateX(0);

             */
            display: none;
        }
        .close-button {
            top: 0.125rem;
            right: 0.125rem;
            background: none;
            border: 0;
        }
    }
}