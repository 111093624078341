.w-timeline {
    @include size(100%, 17rem);
    box-sizing: border-box;
    padding-top: 14rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: darken($primary-color-1, 10%);
    z-index: 100;
    @include shadow;
    transform: translateY(22rem);
    opacity: 0;
    transition: all .3s;
    &.is-open {
        transform: translateY(0);
        opacity: 1;
    }
    &.loading {
        &:after {
            background-color: transparent;
            background-image: url('/assets/images/loading-3.svg');
        }
    }
    .timeline-option {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 2rem;
        height: 2.5rem;
        padding: .5rem;
        border-radius: 0 0 $corner2x $corner2x;
        background-color: $primary-color-1;
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
        &.is-open {
            .w-time-picker {
                width: 11rem;
            }
            .option-btn {
                &:after {
                    transform: rotate(-270deg);
                }
            }
        }
        .form-label {
            color: $primary-color-5;
            font-size: .8rem;
            text-align: left;
            margin: 0 0 0 .5rem;
        }
        .w-time-picker {
            display: flex;
            align-items: center;
            width: 0;
            margin-left: 1rem;
            overflow: hidden;
            transition: .3s all;

            span {
                @include font-family-custom();
                @include font-style(1rem, 700, $primary-color-5);
                margin: 0 .25rem;
                font-size: 1.5rem;
            }
            &:before {
                color: $accent-1;
                font-size: 1.5rem;
                margin-right: .5rem;
            }
        }
        input {
            background-color: darken($primary-color-1, 10%);
            border: 1px solid $accent-1;
            border-radius: $corner2x;
            text-align: center;
            @include font-family-custom();
            @include font-style(1rem, 700, $primary-color-5);
            margin-left: 0;
            width: 2rem;
            height: 2rem;
            padding: 0 0 0 0.125rem;
            flex: none;
            &:focus {
                border: 1px solid $accent-1;
            }
        }
        .option-btn {
            &:after {
                font-size: 2.25rem;
                color: $accent-1;
                transform: rotate(-90deg);
            }
        }
    }
    .btn {
        background-color: $secondary-2;
        transition: all .3s;
        &.alert {
            background-color: $secondary-3;
            &:hover {
                color: $secondary-3;
            }
        }
        span {
            display: none;
        }
        &:hover {
            background-color: $primary-color-4;
            color: $secondary-2;
        }
    }
    .w-timeline-btn {
        background-color: $primary-color-1;
        border-radius: $corner $corner 0 0;
        height: 5rem;
        padding: 0 .5rem;
        position: absolute;
        top: -5rem;
        right: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn {
            margin: 0 .5rem;
            border-radius: $corner;

        }
    }
    .timeline-list {
        display: flex;
        @include size(100%, 1px);
        background-color: $primary-color-1;
        padding-left: 1rem;
        .timeline-item {
            position: relative;
            & + .timeline-item {
                margin-left: .5rem;
            }
            &:first-of-type {
                .w-tooltips-adv {
                    .tooltips-adv {
                        //width: autorem;
                        left: 0;
                        transform-origin: left center;
                        right: auto;
                        transform: translateX(0);
                        left: 0;
                        &:before {
                            left: 4.5rem;
                            transform: rotate(180deg) translateX(3.6rem);
                        }
                        p {
                            //word-break: break-all;
                        }
                    }
                }
            }

            .timeline-unit {
                display: flex;
                flex-direction: column;
                background-color: $primary-color-1;
                border-radius: $corner2x;
                @include size(5rem, 6rem);
                transform: translateY(calc(-100% - 1rem));

                &:before {
                    font-size: 2.75rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    content: "\e99f";
                    color: darken($secondary-color-1, 10%);
                    flex: 1 auto;
                }



                time {
                    border-radius: 0 0 $corner2x $corner2x;
                    padding: .25rem;
                    background-color: darken($primary-color-1, 10%);
                    border: 1px solid $primary-color-1;
                    @include font-family-custom();
                    @include font-style(0.9em, 700, $primary-color-5);
                }
            }
            .w-tooltips-adv {
                position: absolute;
                top: -7.75rem;
                right: 0.5rem;
                z-index: 20;
                .tooltips-adv {
                    width: auto;
                    bottom:  2.75rem !important;
                    height: auto;

                    left: auto;
                    right: -50%;
                    transform: translateX(50%);
                    &:before {
                        bottom: -.9rem !important;
                        transform: rotate(180deg) translateX(1.6rem);
                    }
                }
                .open-button, .close-button {

                }
                .open-button {
                    transform: scale(0.8, 0.8) ;
                }
                .tooltips-adv-content {
                    max-height: 20rem;
                    height: auto;
                    p {
                        //word-break: break-all;
                        display: flex;
                        align-items: center;
                        &:last-of-type {
                            margin-bottom: 1rem;
                        }
                        &:before {
                            content: '-';
                            display: inline-block;
                            color: $accent-1;
                            margin-right: .25rem;
                        }
                    }
                }
            }
            /*
            .timeline-details {
                display: none;
                position: absolute;
                background-color: $primary-color-1;
                border-radius: $corner2x;
                padding: 0 0 1rem 0;
                bottom: 81px;
                transform: translateX(-50%);
                left: 50%;
                z-index: 10;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: -.8rem;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 8.7px 5px 0 5px;
                    border-color: $primary-color-1 transparent transparent transparent;
                }
                .timeline-details-header {
                    text-align: left;
                    padding: .5rem 1rem;
                    background-color: darken($primary-color-1, 5%);
                    border-radius: $corner2x $corner2x 0 0;
                    @include font-family-custom();
                    @include font-style(1.2rem, 700, $secondary-2);
                }
                ul {
                    padding: .5rem 1rem 0;
                }
                li {
                    text-align: left;
                    @include font-family-custom();
                    @include font-style(1rem, 400, $primary-color-5);
                    display: flex;
                    &:before {
                        content: '-';
                        margin-right: .25rem;
                        color: $secondary-2;
                    }
                }
            }
            */
            &.is-selected {
                .timeline-unit {
                    background-color: $accent-1;
                    border-color: $accent-1;
                    time {
                        border-color: $accent-1;
                    }
                    &:before {
                        color: lighten($accent-1, 25%);
                    }
                }
            }
            &.is-moved {
                .timeline-unit {
                    opacity: .5;
                    background-color: $accent-1;
                    border-color: $accent-1;
                    time {
                        border-color: $accent-1;
                    }
                    &:before {
                        color: lighten($accent-1, 10%);
                    }
                }

            }
            &.is-conflict {
                .timeline-unit {
                    background-color: $secondary-5;
                    //margin-right: -2.5rem;
                    &:before {
                        color: darken($secondary-5, 35%);
                    }
                }
                .time {
                    border-radius: 0 0 $corner2x $corner2x;
                    padding: .25rem;
                    background-color: darken($secondary-5, 35%);
                    border: 1px solid $secondary-5;
                    @include font-family-custom();
                    @include font-style(0.9em, 700, lighten($secondary-5, 27%));
                }
            }
        }
        .timeline-btn {
            @include size(1.5rem, 1.5rem);
            flex: none;
            border-radius: 50%;
            background-color: darken($secondary-color-1, 10%);
            transform: translateY(-50%);
            transition: all .3s;
            &:hover {
                background-color: lighten($accent-1, 10%);
            }
            & + .timeline-btn {
                margin-left: .5rem;
            }
            &:first-of-type {
                &.is-selected {
                    .timeline-marker {
                        left: calc(-50% - 2.5rem);
                        &:before {
                            left: calc(50% - 2rem);
                        }
                        &:after {
                            left: calc(50% - 2rem);
                        }
                    }

                }
            }
            &.is-selected {
                background-color:  $accent-1;
                pointer-events: none;
            }
            &.is-not-selected {
                background: none;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: .3rem;
                    left: 50%;
                    transform: translateX(-50%);
                    width: .75rem;
                    height: .75rem;
                    background-color: $accent-1;
                    border-radius: 50%;
                }
            }
            &.is-conflict {
                background: none;
                width: 0;
                .timeline-marker {
                    background-color: $accent-2;
                    @include font-style(0.9em, 700, $primary-color-5);
                    left: calc(-50% - 8rem);
                    transform: translateY(-1.5rem);
                    &:before {
                        border-color: $accent-2 transparent transparent transparent;
                    }
                    &:after {
                        display: none;

                    }
                    span {
                        &:before {
                            color: lighten($primary-color-5, 67%);
                        }
                        &.i-arrow-right {
                            &:before {
                                color: darken($primary-color-5, 55%);
                            }
                        }
                    }
                    time {
                        color: darken($primary-color-5, 35%);
                    }
                }
            }
        }
        .timeline-day {
            display: flex;
            align-items: center;
            @include size(auto, 2rem);
            box-sizing: border-box;
            border-radius: $corner2x;
            padding: .25rem .5rem;
            margin: 0 -1rem;
            white-space: nowrap;
            transform: translateY(-135%) rotate(-90deg);
            background-color: $primary-color-1;
            @include font-family-custom();
            @include font-style(0.9em, 700, $primary-color-5);

        }
        .timeline-marker {
            @include font-family-custom();
            @include font-style(0.9em, 700, $primary-color-5);
            width: 11rem;
            display: flex;
            align-items: center;
            background-color: $accent-1;
            border-radius: 5px;
            padding: 0.5rem;
            position: absolute;
            top: -8rem;
            left: calc(-50% - 4.4rem);
            transform: translateY(-50%);
            justify-content: center;
            animation: fadeInAnimation ease .45s;
            @keyframes fadeInAnimation {
                0% {
                    opacity: 0;

                }
                100% {
                    opacity: 1;
                }
            }
            &:after {
                content: '';
                background-color: $accent-1;
                display: inline-block;
                @include size(1px, 7rem);
                position: absolute;
                top: 3rem;
                left: calc(50% - 1px);
                transform: translateX(-50%);
            }
            &:before {
                content: '';
                position: absolute;
                bottom: -.9rem;
                left: calc(50% - 1px);
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8.7px 5px 0 5px;
                border-color: $accent-1 transparent transparent transparent;
            }
            span {
                transform: translateY(-1px);
                &:before {
                    color: darken($accent-1, 50%);
                    font-size: 1.25rem;
                }
                &.i-arrow-right {
                    &:before {
                        color: fade-out($primary-color-1, .5);
                        font-size: 1.25rem;
                    }
                }
            }
            time {
                color: $primary-color-1;
                font-size: 1.1rem;
                margin-left: .5rem;
            }
        }
    }
}

@keyframes pulse {
    from { transform: scale(1); }
    30% { transform: scale(1.2); }
    to { transform: scale(1); }
}
