.sidenav {

}
/*
.expend-label {
    @include font-style(1.2rem, 700, $primary-color-1);
    background-color: $accent-2;
    padding: .5rem 1rem;
    position: absolute;
    bottom: -2.5rem;
    cursor: pointer;
    left: 0;
}
.expend-input {
    display: none;
}
.expend-input:checked ~ .sidebar-staff-list {
    height: auto;
}
.expend-input:checked ~ .expend-label {
    display: none;
}
*/

#content {
    transition: all .25s;
}

body {
    //&.is-open {
        #content {
            //transform: translateX(17rem);
            padding-left: 18rem;
        }
        #mainNavMenu {
            width: 18rem;
        }
        .btn-sidebar {
            transform: translateX(13rem);
            &:after {
                transform: rotate(90deg);
            }
        }
        .prop-dropdown {
            .dropdown-toggle {
                //width: 23rem;
                span {
                    //opacity: 1;
                }

        }
        .dropdown-list {
            //left: 23rem;
        }
    }
    /*}*/
    &.is-closed {

        #content {
            //transform: translateX(17rem);
            padding-left: 5rem;
        }
        #mainNavMenu {
            width: 5rem;
        }
        .btn-sidebar {
            transform: translateX(0rem);
            &:after {
                transform: rotate(-90deg);
            }
        }

        .prop-dropdown {
            .dropdown-toggle {
                //width: 23rem;
                span {
                    //opacity: 1;
                }

            }
            .dropdown-list {
                //left: 23rem;
            }
        }
    }
}

.btn-sidebar {
    //display: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    //background-color: $accent-2;
    border: 2px solid $accent-2;
    z-index: 60;
    bottom: 1.25rem;
    position: fixed;
    left: 1rem;
    border-radius: 50%;
    transform: rotate(-90deg);
    @include shadow;
    transition: .25s all;
    ._ & {
        display: none;
    }
    &:after {
        color: $accent-2;
        font-size: 2.25rem;
        //margin-left: .25rem;
    }
}

.prop-dropdown {
    .dropdown-toggle {
        display: flex;
        font-weight: 700;
        padding: 0;
        justify-content: flex-end;
        width: 5.5rem;
        height: 5.5rem;
        color: $primary-color-1;
        background-color: fade-out($primary-color-5, .75);
        border: 0;
        box-shadow: none;
        overflow: hidden;
        transition: all .25s;
        border-radius: 0;
        background-repeat: no-repeat;
        background-position: 15px center ;
        background-size: auto 50%;
        background-image: url(/assets/images/logos/prop-menu.svg);
        span {
            flex: 1 auto;
            text-align: right;
            margin-right: 2rem;
            opacity: 0;
            transition: all .25s;

        }
        &:before {
            display: none !important;
        }
        &:after {
            display: none;
            content: "\e8ea" !important;

            margin-right: 2rem;
            color: $accent-2;
        }
        &.full-icon {
            padding: 0;
            background-position: 15px center ;

            &.i-AP {
                background-position: 8px center ;
                background-size: auto 45%;
            }

            &:after {
                //display: block;
            }
        }
    }
    .dropdown-list {
        top: 5.5rem;
        left: 0;
        width: 34rem !important;
        height: auto !important;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .main-nav-item {
        box-sizing: border-box;
        //flex: 2 50%;
        width: 50%;
        border-bottom: 1px solid fade-out($primary-color-1, .95);
        &:nth-of-type(odd) {
            border-right: 1px solid fade-out($primary-color-1, .95);
        }
    }
    .main-nav-link {
        display: flex !important;
        align-items: center;
        padding: .5rem 4rem .5rem 4rem;
        margin-bottom: 0;
        height: 2rem;
        .full-icon {
            background-size: auto 35%;
        }
        &:hover {
            color: $accent-2;
            background-color: lighten($primary-color-3, 5%);
        }
    }
}

#mainNavMenu {
    background-color: fade-out($primary-color-4, .5);
    border-right: 1px solid darken($primary-color-4, 5%);
    @include size(5rem, 100%);
    position: fixed;
    padding-top: 0;
    left: 0;
    top: 5.6rem;
    bottom: 0;
    color: $primary-color-1;
    @include shadow;
    //overflow: hidden;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    //width: 23rem;
    transition: all .25s;
    &.is-open {
       // width: 20.25rem;
    }
    & > .main-nav-list .dropdown-toggle {
        display: none;
    }
    .main-nav-list {
        width: 100%;
    }
    .dropdown-list {
        width: 100%;
        flex-direction: column;
        display: flex;
        opacity: 1;
        visibility: visible;
        background: none;
        .dropdown {
            border-bottom: 1px solid darken($primary-color-4, 5%);
        }
    }
    .main-nav-item {
        width: 100%;
        border-bottom: 1px solid $border;
    }
    .main-nav-link, .dropdown .dropdown-link {
        background-color: transparent;
        @include size(auto, 4rem);
        padding: 0 2rem 0 0 !important;
        border: 0;
        //border-bottom: 1px solid $border;
        color: $primary-color-1;
        display: flex;
        align-items: center;
        //justify-content: space-between;
        box-sizing: border-box;
        white-space: normal;
        text-align: left;
        &.is-selected {
            background-color: fade-out($primary-color-5, .75);
        }
        &:hover {
            background-color: fade-out($primary-color-5, .75);
        }
        &:before {
            @include size(5rem, auto);
            font-size: 2.125rem;
            color: $accent-2;
            @include icon-glow($primary-color-5);
            margin-right: 1.25rem;
            text-align: center;
            flex: none;
        }
        &:after {
            display: none;
        }
        &.i-ads {
            &:before {
                font-size: 2rem;
            }
        }
        &.i-config {
            &:before {
                //margin-left: .5rem;
            }
        }
        &.i-data, &.i-cat, &.i-geo {
            &:before {
                font-size: 1.4rem;
            }
        }
        &.i-sspConfig {
            &:before {
                font-size: 2.6rem;
            }
        }
        &.i-template {
            &:before {
                font-size: 1.4rem;
            }
        }
        &.i-ad_template_2, &.i-ad_type_2 {
            &:before {
                font-size: 2.125rem;
            }
        }
    }
}


