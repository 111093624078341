.main-header {
    height: 5.5rem;
    padding: 0 0 0 18rem;
    background-color: fade-out($primary-color-4, .05);
    border-bottom: 1px solid fade-out($primary-color-5, .75);
    @include shadow;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .w-right {
        flex: 1;
        display: flex;
        flex-wrap: nowrap;
        .header {
            flex: 1;
        }
        .btn {
            margin-left: 1rem;
            border: 0;
        }
        & > div {
            margin-left: 0;
            border-right: 1px solid $primary-color-5;
        }
        .dropdown-list {
            top: 5.5rem;
            right: -2px;

        }
        a {
            display: block;
            white-space: nowrap;
            &:before {
                margin-right: .5rem;

            }
        }
    }
    .w-left {
        flex: 1;
        .dropdown {
            a {
                display: block;
                white-space: nowrap;
                &:before {
                    margin-right: .5rem;

                }
            }
        }
        .dropdown-list {
            min-width: 15rem;
            width: 100%;
        }
    }
    .page-title  {
       //display: none;
        text-transform: uppercase;
        display: inline-block;
        margin-left: -10rem;
        text-align: center;
        @include font-family-custom();
        @include font-style(1.2rem, 700, $primary-color-1);
    }
    .prop-title {
        display: none;
    }
    .dropdown-link {
        &:before {
            margin-right: 2rem;
            font-size: 1.7rem;
            color: $accent-1-text;
            width: 2.5rem;
        }
        &.i-ssp, &.i-group, &.i-health, &.i-geogroup {
            &:before {
                font-size: 2rem;
            }
        }
    }
}


.site-logo {
    background: url('/assets/images/logo-full.svg') no-repeat;
    @include size(16rem, 6rem);
    background-position: left -.125rem;
    background-size: auto 92%;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;

    a {
        display: block;
        @include size(100%, 100%);
    }
    span {
        display: none;
    }
}

.w-main-nav, .main-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 6rem;
}

.w-main-nav {
    div:not(.w-validation-errors) {
        display: flex;
    }
}



.main-nav-list {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: scroll;
}

.main-nav-item {
}

.main-nav {
    .main-nav-link {
        background-color: fade-out($primary-color-5, .75);
        padding: 0 4rem;
        height: 100%;
        @include font-family-custom();
        @include font-style(1rem, 700, $primary-color-1);
        text-transform: capitalize;
        display: flex;
        align-items: center;
        text-align: left;
        white-space: nowrap;
        &:before {

            font-size: 2em;
            vertical-align: middle;
            color: $accent-1;
            @include icon-glow($accent-1);
        }

        &:hover {
            color: $accent-1;
            background-color: $primary-color-2;
            &:before {
                color: $accent-1;
                @include icon-glow($accent-1);
            }
            &:after {
                color: $primary-color-1;
            }
        }

    }
    .dropdown-item {
        position: relative;
        a {
            //padding: 1.2rem 4rem 0;
        }
    }
    .dropdown-item {
        &:first-of-type {
            a {
                margin-top: .6rem;
            }
        }
        &:last-of-type {
            a {
                margin-bottom: .6rem;
            }
        }
    }
    .dropdown-toggle {
        color: $primary-color-1;
    }
}

.main-nav-link {
    &.user {
        border: 0;
        padding: 0 0 0 2rem;
    }
}

.user-section, .w-user-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    cursor: pointer;
    &:hover .w-user-info {
        color: $primary-color-1;
    }
    .w-user-info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: right;
        color: fade-out($primary-color-1, .5);
        margin-left: 1rem;
    }
    .w-user-role {
        font-size: .8rem;

    }
    strong {
        display: block;
    }
    p {

    }
    .user-name {
        display: block;
        white-space: nowrap;
    }
    .user-pic, .log-out {
        padding: 0;
        @include shadow;
    }
    .user-pic a {
        padding: 0 1rem;
    }
    .w-user-img {
        background-color: $primary-color-5;
        margin-left: 1.5rem;
        overflow: hidden;
        @include size(55px, 55px);
        img {
            display: block;
            @include size(auto, 55px);
        }
    }
}


/* ----------------- sub header */

.sub-header {
    min-height: 5rem;
    margin-bottom: 2rem;
    @include general-padding;
    padding-top: 2rem;
    box-sizing: border-box;
    .home & {
        padding-top: 1.5rem;
    }
    .w-left, .w-right, .w-center {
        & > div {
            margin-bottom: 2rem;

        }
    }
    .w-left {
        & > div {
            margin-right: 1rem;
        }
    }
    .w-right {
        & > div {
            margin-left: 1rem;
        }
    }
    .align-btm {
        align-items: flex-end;
    }
    .w-justify {
        &:nth-of-type(2) {
            padding-top: 2rem;
        }
        & > div {
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    }
    .d-inline-flex {
        display: inline-flex;
        margin: 0 !important;
    }
    .emp-filters {
        padding: .25rem;
        //border: 1px solid fade-out($accent-1, .5);
        display: flex;
        flex-direction: column;
        background-color: fade-out($accent-1, .5);
        .date-picker {
            width: 18.5rem;
        }
    }
    .border-left {
        border-left: 1px solid $accent-1;
        padding-left: 2rem;
        display: flex;

    }
    .apply {
        margin-right: 0 !important;
        select {
            border-radius: $corner 0 0 $corner;
            margin-right: 0;
        }
        & + .btn {
            height: 3.9rem;
        }
        & + .highlight {
            background-color:  $accent-1;
            color: $primary-color-1;
        }
    }
    .btn {
       //margin-bottom: 1rem;
        margin-right: 2rem;
        margin-bottom: 2rem;
       //height: 4rem;
        //background-color: $primary-color-2;
        &.left-join {
            border-radius: 0 $corner $corner 0;
            margin-left: 0;
        }
    }
    .input-text {

        &.num {
            height: 4rem;
            padding-top: 1.5rem;
            width: 19rem;
            &:placeholder {
                color: red;
            }
        }
    }
}

.sub-header, .w-form-label-box {
    .form-label-box, label {
        @include font-style(1rem, 700, $accent-1-text);
        position: absolute;
        margin: .65rem 0 0 1rem;
        z-index: 20;
        pointer-events: none;
        text-transform: uppercase;
        &.center {
          margin-top: 1.5rem;
            //color: $primary-color-5;
        }
        &.multi {
            margin-top: 1.5rem;
        }
    }
    .form-check-inline {
        .form-label {
            //color: $primary-color-1 !important;

            &:before {
                //color: $primary-color-5 !important;
            }
            &:after {
                //background-color: $accent-1;
            }
        }
    }

    input[type="checkbox"] + label {
        margin-bottom: 2rem ;
        pointer-events: auto;
        //@include font-style(1rem, 700, $primary-color-5);
    }
    input[type="checkbox"] + label:after {
        //background-color: $accent-1;
    }
    input[type="checkbox"] + label:before {

    }
    select, .dropdown-toggle {
        @include size(19rem, 4rem);
        text-align: left;
        @include font-style(1.2rem, 700, $primary-color-1);
        background-color: fade-out($primary-color-5, .5);
        border: 1px solid fade-out($accent-1, .5);
        box-shadow: none;
        //border: 1px solid fade-out($accent-1, .5);
        background-image: url('/assets/images/dropdown-bg.svg');
        background-size: 24px 24px;
        background-position: 95% center;
        background-repeat: no-repeat;
        @include shadow;
        margin: 0;
        padding: 1.5rem 3rem 0 1rem;
        text-transform: capitalize;
        cursor: pointer;
        box-sizing: border-box;
        border-radius: $corner;
        option {
            @-moz-document url-prefix() {
                font-size: 1.2rem;
                background-color: $primary-color-3;
                margin-bottom: .25rem;
            }

        }
        &:hover {
            border-color: $accent-1;
        }
        ._users & {
            background-image: url('/assets/images/multi-bg.svg');
            background-size: 30px 30px;
        }
    }
    .multi + div .dropdown-toggle {
        background-image: url('/assets/images/multi-bg.svg');
        background-size: 30px 30px;
        padding-top: 0;
        color: lighten($accent-1-text, 10%);
        font-size: 1rem;
        text-transform: uppercase;

    }
    .dropdown {
        .dropdown-list {
            background-color: lighten($primary-color-4, 15%);
            min-width: 50rem;
            left: 0;
            top: 4rem;
            .form-label {
                margin-bottom: .25rem;
                white-space: nowrap;
                min-width: 6rem;
            }
        }
        .form-footer {
            background-color: lighten($primary-color-4, 8%);
        }
        .multi-row {
            display: inline-flex;
            align-items: center;
            //width: 14rem;
            flex: 1 40%;
        }
        .form-group {
            //columns: 3;
            display: flex;
            flex-wrap: wrap;
        }
        .note {
            @include font-style(1.2rem, 700, $primary-color-5);
            background-color: $primary-color-2;
            padding: .25rem .5rem;
            margin: 0 0 .5rem 1rem;
            position: relative;
            &:before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 8.7px 5px 0;
                border-color: transparent $primary-color-2 transparent transparent;
                position: absolute;
                left: -8px;
                top: calc(50% - 5px);
            }
        }
        &.select-single {
            .dropdown-toggle {
                background-image: url('/assets/images/dropdown-bg.svg');
                padding: 1.5rem 3rem 0 1rem;
            }
        }

    }
}


/* to be refactor multipleSelector */
/* replace .w-form */

.sub-header {
    .w-form {

        width: auto;
        min-width: auto;
        padding-top: 2rem;
        margin: 0;
        background: none;

        label {
            text-transform: uppercase;
            //margin: 0 0 .5rem 0;
            margin-bottom: .5rem !important;
            @include font-family-custom();
            @extend .icon;
            //white-space: nowrap;
            &:before {

            }
        }



        .form-footer {
            padding: 1rem;

            .btn {
                margin-bottom: 0;
            }
        }


        /*
        .column {
            columns: 3;
            padding: 1rem;

        }
        */
    }

}


/* small tags under sub-header when a filter is selected */
/* temp */

.w-filters-selected {
    background-color: fade-out($primary-color-5, .85);

    .filters-selected {
        margin: -2rem 3rem 2rem;
    }
    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 3rem;
    }
    label.small { /* form-label */
        @extend .form-label;
        display: inline-block;
        margin-top: .65rem;
    }
    .btn-f-s {
        height: 1.6rem;
        margin: .25rem;
        padding-right: .5rem;
        padding-top: .125rem;
        margin-top: .5rem;
        background-color: lighten($primary-color-4, 12%);
        @include transition;
        @extend .form-label;
        color: $primary-color-1 !important;
        border-radius: $corner;
        display: flex;
        align-items: center;
        &:before {
            color: $primary-color-1;
            font-size: 1.4rem;
        }
        &:hover {
            background-color: $accent-1;
            color: $primary-color-5;
        }
    }
}

/* specific dropdown filter */
/* specific sub-header */

/*  grouping */

#zoneFilter,
#geosFilter {
    .w-form {
        width: auto;
        margin: 0;
    }
    .multi-row {
        width: 24rem;
    }
    .form-group {
        //flex-wrap: wrap;
        margin-top: 0;
        display: flex;
        & > div {
            min-width: 10rem;
            padding-bottom: 1rem;
        }
    }
    .margin-left {
        margin-left: 2rem;
    }
    .dropdown-list {

        width: 105rem;
        left: -40rem;
        right: auto;
    }
}

.status-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 19rem;
    height: 4.25rem;
    border: 1px solid darken($border, 5%);
    position: relative;
    border-radius: 3px;
    background-color: fade-out($primary-color-5, .5);
    border: 1px solid fade-out($accent-1, .5);
    & > .form-label {
        color: $accent-1-text !important;
        position: absolute;
        top: 0;
        left: -3px;
    }
    .form-label {
        color: $primary-color-3;
    }
    .form-group {
        margin: -2px 0 0 4.25em;
        input[type="radio"] + .form-label {
            line-height: 1rem;
            padding-left: 2rem;
            &:nth-of-type(4) {
                margin-left: 5.75rem;
            }
            &:before {
                width: 1.25rem;
                height: 1.25rem;
                top: -.25rem;
                border: 1px solid fade-out($primary-color-1, .8);
            }
            &:after {
                width: 0.5rem;
                height: 0.5rem;
                top: 0.25rem;
                left: 0.45rem;
                background-color: $accent-2 ;
            }
        }
    }
}

.status-filter {
    input[type="radio"] + .form-label {
        &.status {
            span {
                display: inline-block;
                @extend .icon;
                &:before {
                    font-size: 1.25rem;
                    margin: 0;
                    display: inline-flex !important;
                    justify-content: center;
                    background-color: lime;
                }
            }
        }
        &.status-pending {
            span {
                &:before {
                    content: '\e98d';
                    color: $pending;
                }
            }
        }
        &.status-all {
            span {
                &:before {
                    content: 'all';
                    font-family: 'Lato', sans-serif !important;
                    font-weight: 700 !important;
                    font-size: 1.25rem;
                }
            }
        }
        &.status-done {
            span {
                &:before {
                    content: '\e939';
                    color: $secondary-4;
                }
            }
        }
        &.status-error {
            span {
                &:before {
                    content: '\e926';
                    color: $secondary-3;
                    font-size: 2.5rem;
                    margin-left: -.5rem;
                }
            }
        }
        &.status-in-progress {
            margin-left: 0.25rem;
            span {
                &:before {
                    content: '\e9a1';
                    color: $complete;
                    font-size: 1.5rem;
                }
            }
        }
    }
}
