.w-double-list {
    @extend .w-form;
    @extend .w-form-large;
    display: block;
    margin-bottom: 2rem;
    .desc {
        height: 4.5rem;
    }
}


.double-list-header {
    @extend .form-header;
    .double-list-title {
        @extend .form-title;
        display: block;
    }
}

.w-double-list-body {
    @extend .w-form-body;
}

.double-list-body {
    @extend .form-body;
    padding: 2rem;
    .double-list-sub-title {
        @extend .form-sub-title;
        padding: .5rem !important;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem !important;
        span {
            font-size: 1.6rem;
        }
    }

    .double-list-text {
        @extend .form-text;
    }
}


.double-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    span {
        background-color: lighten($primary-color-4, 10%);
        padding-bottom: .5rem;
    }
    dt, dd {
        border-bottom: 1px dashed fade-out($primary-color-1, .9);
        padding: 0;
        &.full-width {
            grid-column-end: span 2;
            text-align: left;
            border-bottom: 0;
        }
    }
    dt {
        text-align: left;
        @extend .form-label;
        font-weight: 700 !important;
        margin-top: .5rem;
    }
    dd {
        text-align: right;
        text-transform: capitalize;
        .column & {
            text-align: left;
        }
        @extend .form-label;
        @include font-style(1.3em, 400, $primary-color-1);

    }
    &.column {
        display: block;
        dt, dd {
            border: 0;
            text-align: left;
            margin-top: 0;
        }
    }
}
