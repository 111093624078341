#chartDiv_featurePoints {
    width: 100%;
    height: 100%;
}

.dash-chart-feature-points {
    @include aspect-ratio(16,6);
}

a[title='JavaScript charts'] {
    display: none !important;
}