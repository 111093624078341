html{color:#000;}a{color:#000;text-decoration:none}big{font-size:100%}body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td{margin:0;padding:0}table{border-collapse:collapse;border-spacing:0}fieldset,img{border:0}address,caption,cite,code,dfn,th,var{font-style:normal;font-weight:normal}li{list-style:none}caption,th{text-align:left}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}q:before,q:after{content:''}abbr,acronym{border:0;font-variant:normal}sup{vertical-align:text-top}sub{vertical-align:text-bottom}input,textarea,select{font-family:inherit;font-size:inherit;font-weight:inherit}input,textarea,select{font-size:100%}legend{color:#000}body{font:13px/1.231 arial,helvetica,clean,sans-serif;font-size:small;font:x-small}table{font-size:inherit;font:100%}pre,code,kbd,samp,tt{font-family:monospace;font-size:108%;line-height:100%}
button { border: 0; background: none; cursor: pointer; outline: 0; padding: 0;}
input { outline: 0}

iframe {border: 0;}

a, button {outline: none;}
a:hover {text-decoration: none}
a:active {text-decoration: none}
a:focus {text-decoration: none}

button {-webkit-appearance: none;border-radius: 0;}

/* to resent font so 1em = 10px */
html, body {font-size:10px}
