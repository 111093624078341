.report-list {
    margin: 0 $site-padding;
    width: calc(100% - $site-padding);
    .report-item {
        margin-bottom: 4rem;
    }
}

.report-brand-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-left: 7.5rem;
    background-position: 1rem 1rem;
    background-size: 4.5rem 4.5rem;
    background-image: url(/assets/images/logos/VN.svg);
    &:before {
        content: '';
        @include size(6.5rem, 6.5rem);
        background-color: fade-out($primary-color-5, .5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: $corner + 2px;
    }
    .report-brand-item {
        @include size(32%, 6rem);
        display: flex;
        margin: 0 1rem 1rem 0;
        margin-bottom: 1rem;
        @include shadow;
        box-shadow: inset 0px 0px 20px 0px fade-out($primary-color-5, .85), 0px 0px 10px 0px fade-out($primary-color-5, .8);
        @include desktop-large {
            @include size(30rem, 6.5rem);
        }
        & > div {
            display: flex;
            flex-direction: column;
            justify-content: center;

        }
        .w-left {
            background-color: fade-out($primary-color-5, .5);
            align-items: flex-start;
            padding-left: 1.5rem;
            box-sizing: border-box;
            width: 50%;
            border-radius: 4px 0 0 4px;
        }
        .w-right {
            background-color: fade-out($primary-color-5, .75);
            align-items: center;
            width: 50%;
            border-radius: 0 4px 4px 0;
        }
        .width-tooltips {
            position: relative;
            .w-tooltips-adv {
                position: absolute;
                right: -16px;
                text-transform: none;
                top: -6px;
            }
            .open-button {
                transform: scale(.8);
            }
        }
        .metric {
            @include font-family-custom();
            @include font-style(1.2em, 900, $secondary-color-1);
            text-transform: uppercase;
            text-align: left;
        }
        .value {
            @include font-family-custom();
            @include font-style(1.1em, 900, $primary-color-1);
            margin-top: .35rem;
            &:before {
                color: $accent-1;
                margin-right: .25rem;
                font-size: .85rem;
            }
        }
        .value + .value {
            margin-top: 0;
        }
        .change {
            @include font-family-custom();
            @include font-style(1.8em, 900, $primary-color-1);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: left;
            width: 100%;
            &:before {
                content: '';
                flex: none;
                display: block;
                //margin-right: 1rem;
                width: 2.2rem;
                height: 2rem;

                transform-origin: center center;
                background-repeat: no-repeat;
                background-position: center right;
                background-size: auto 100%;
            }
        }
        &.indic-very-good {
            .change {
                margin-left: 7rem;
                color: $report-2;
                @include icon-glow($report-2);
                &:before {
                    background-image: url(/assets/images/arrow-marker.svg);
                    transform: translateX(-1rem);
                }
            }
        }
        &.indic-good {
            .change {
                margin-left: 7rem;
                color: $report-1;
                @include icon-glow($report-1);
                &:before {
                    background-image: url(/assets/images/arrow-marker-good.svg);
                    transform: translateX(-1rem);
                }
            }
        }
        &.indic-bad {
            .change {
                margin-left: 6.6rem;
                color: $report--1;
                @include icon-glow($report--1);
                &:before {
                    transform: translateX(-.75rem) rotate(180deg);
                    background-image: url(/assets/images/arrow-marker-bad.svg);
                }
            }
        }
        &.indic-very-bad {
            .change {
                margin-left: 6.7rem;
                color: $report--2;
                @include icon-glow($report--2);
                &:before {
                    transform: translateX(-.75rem) rotate(180deg);
                    background-image: url(/assets/images/arrow-marker-very-bad.svg);
                }
            }
        }
    }
}

