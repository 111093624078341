body, html {
    height: 100%;

    scrollbar-width: thin;          /* "auto" or "thin" */
    scrollbar-color: $primary-color-5 lighten($primary-color-4, 15%);   /* scroll thumb and track */
}

* {
    scrollbar-width: thin;
    scrollbar-color: $primary-color-5 lighten($primary-color-4, 15%);
}

*::-webkit-scrollbar {
    width: 0;
}

*::-webkit-scrollbar-track {
    background: $primary-color-5;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
    background-color: lighten($primary-color-4, 15%);    /* color of the scroll thumb */
    border-radius: 0px;       /* roundness of the scroll thumb */
    border: 0px solid $primary-color-5;  /* creates padding around scroll thumb */
}

body {
    letter-spacing: .05em;
    background: lighten($primary-color-4, 10%);
    background: linear-gradient(180deg, #333c4c 0%, #272E3C 100%);
    background-attachment: fixed;
    @include font-family-custom();
}


.w-justify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .w-left {
        text-align: left;
        justify-content: flex-start;
        & > div {
            //margin-right: 2rem;
        }
    }
    .w-center {
        justify-content: center;
        & > * {
            margin: 0 .5rem;
        }
    }
    .w-right {
        justify-content: flex-end;
        & > div {
            margin-left: 1rem;
        }
    }
    .flex-none {
        flex: none;
    }
}

.content, #content {
    text-align: center;
    padding-left: 6rem;
    padding-top: 8rem;
    &.dev_featurePoints {
        padding-top: 2rem;
        text-align: left;
    }
}

.w-content {

}

.w-section-title {
    border-bottom: 1px solid $border;
    background-color: $primary-color-1;
    @include icon-glow($primary-color-1);
    padding: 0 $site-padding;
    padding-bottom: .5rem;
    padding-top: .5rem;
}


.section-title {
    @include font-family-custom();
    @include font-style(1.4em, 700, $primary-color-1);
    text-transform: uppercase;
    text-align: left;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    &:before {
        margin-right: .5rem;
        font-size: 1.25em;
        color: $accent-1-text;
    }
    &.i-health {
        &:before {
            font-size: 1.5em;
        }
    }
}

.autoRefresh {
    margin-right: 1.75rem;
}

.btn {
    @include font-family-custom();
    @include font-style(1.2em, 900, $primary-color-1);
    @include size(auto, 3rem);
    box-sizing: border-box;
    background-color: $accent-1;
    box-shadow: none;
    border: 0;
    padding: .25rem 1.25rem .35rem;
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    align-items: center;
    @include shadow;
    border-radius: $corner;
    &:before {
        margin-right: .5rem;
        color: $primary-color-1;
    }
    &:after {
        color: $primary-color-1;
    }
    &:hover {
        background-color: lighten($accent-1, 5%);
    }
    &.highlight {
        color: $primary-color-5;
        background-color: $accent-2;
        margin-left: 1rem;
        &:before {
            color: $primary-color-5;
        }
        &:hover {
            background-color: lighten($accent-2, 20%);

        }
    }
    &.light {

    }
    &.border {
        color: $primary-color-1;
        border: 1px solid $border;
        background-color: fade-out($primary-color-5, .75);
        box-shadow: none;
    }
    &.only-icon {
        @include size(3rem, 3rem);
        padding: 0 !important;
        text-align: center;
        justify-content: center;
        &:before {
            margin: 0 !important;
        }
    }
    &.small {
        @include size(auto, auto);
        font-size: 1rem;
        padding: .25rem .5rem;
        text-transform: uppercase;
    }

    &.btn-toggle {
        @include font-family-custom();
        @include font-style(1.2em, 900,  lighten($secondary-color-1, 15%));
        background: fade-out($secondary-color-1, .85);
        @include size(auto, auto);
        font-size: 1rem;
        padding: .5rem .75rem;
        text-transform: uppercase;
        display: flex;
        border-radius: $corner;
        align-items: center;
        .list-header & {
            height: 3rem;
            font-size: 1.2rem;
            position: relative;
            &:after {
                content: '';
                height: 3rem;
                width: 1px;
                background-color: $secondary-color-1;
                position: absolute;
                right: -1.5rem;
            }
        }
        &:before {
            color: lighten($secondary-color-1, 15%);
            margin-right: .5rem;
        }
        &:hover {
            color: $primary-color-1;
            &:before {
                color: $primary-color-1;
            }
        }
        &.is-toggle.true {
            background: fade-out($accent-2, .75);
            color: $accent-2;
            &:before {
                color: $accent-2;
            }
        }
    }
    &.i-remove {
        background-color: $secondary-3 !important;
    }
    &.i-unit {
        &:before {
            font-size: 1.75rem;
        }
    }
    &.secondary {
        background-color: $accent-1;
    }
    &.alert {
        background-color: $secondary-3;
        &:hover {
            background-color: lighten($secondary-3, 5%);
        }
    }
    &.approved {
        background-color: $secondary-2;
        &:hover {
            background-color: lighten($secondary-2, 5%);
        }
    }
    &.icon-only {
        background-color: $accent-1;
        font-size: 1.3rem;
        @include size(3rem, 3rem);
        padding: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $corner;
        &:before, &:after {
            margin: 0;
            vertical-align: middle;
            line-height: 3rem;
            color: $primary-color-1;
        }
        &:hover {
            background-color: lighten($accent-1, 10%);
        }
        span {
            display: none;
        }
        &.no-bg {
            background: none;
            border: 0;
            &:before, &:after {
                font-size: 1.75rem;
                color: $icon-1;
            }
            &:hover {
                &:before, &:after {
                    color: lighten($icon-1, 15%);
                }
            }

        }
    }
    &.no-bg {
        padding: 0 .5rem;
        background: none !important;
        color: $primary-color-1;
        box-shadow: none;
        &:before {
            color: $accent-1;
            margin-right: .5rem;
        }
        &:hover {
            color: $accent-1;
            &:after {
                color: $primary-color-4;
            }
        }
        &.i-move {
            height: auto;
        }
    }
    &.disable, &.is-disable {
        opacity: .5;
        pointer-events: none;
    }
    &.i-delete {
        background-color: $secondary-3;
        &:hover {
            background-color: lighten($secondary-3, 5%);
        }
    }
    &.large {
        font-size: 1rem;
        width: 7rem;
        height: 5.5rem;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem !important;
        margin: 0 !important;
        color: $primary-color-1;
        box-sizing: border-box;
        background-color: fade-out($primary-color-5, .75);
        box-shadow: none;
        border-left: 1px solid $border;
        position: relative;
        @include transition;
        &:last-of-type {
            border-right: 1px solid $border;
        }
        &:before {
            font-size: 2rem ;
            margin-top: 1rem;
            color: lighten($accent-1, 5%);
            //width: 2rem;
        }
        &:after {
            flex: 1 auto;
            text-align: right;
            margin-left: 1rem;
            font-size: 2rem;
            color: lighten($accent-1, 5%);
        }
        &.hor {
            flex-direction: row;
            align-items: center;
            width: auto;
            min-width: 15rem;
            justify-content: center;
            background-color: fade-out($primary-color-5, .95);
            &:hover {
                background-color: lighten($primary-color-4, 5%);
            }
            &:before {
                margin-top: 0;
                margin-right: .5rem;
                font-size: 2.25rem;
            }
            &.i-code {
                &:before {
                    font-size: 3rem;
                    margin-bottom: -.5rem;
                }
            }
            &.i-report {
                &:before {
                    font-size: 1.75rem;
                }
            }
        }
    }
}

span.hide {
    display: none;
}

.main-nav {
    .btn {
        &.large {
            &.is-selected {
                background-color: lighten($primary-color-3, 15%);
                //pointer-events: none;
                &:after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    left: -9px;
                    height: 3px;
                    background-color: $accent-1;
                    @include icon-glow($accent-1);
                }
            }
        }
    }
}

.btn-action {
    @include size(5rem,5rem);
    span {
        display: none;
    }
    &:before {
        font-size: 4rem;
    }
    &.i-cancel-thin {
        &:before {
            color: $secondary-3;
            @include icon-glow($secondary-3);
        }
        &:hover {
            &:before {
                color: lighten($secondary-3, 5%);
            }
        }
    }
    &.i-save-thin {
        &:before {
            color: $secondary-2;
            @include icon-glow($secondary-2);
        }
        &:hover {
            &:before {
                color: lighten($secondary-2, 5%);
            }
        }
    }
}

#login-form {

    .btn-text {
        color: $primary-color-1 !important;
    }
    .form-body {
        border-bottom: 1px solid $accent-1;
    }
    .form-footer {
        flex-direction: row-reverse;
       .btn {
           &:before {
               font-size: 4rem;
               margin-right: -4rem;
           }
        }
        .btn, .highlight{
            @include size(5rem,5rem);
            background: none;
            box-shadow: none;
            color: transparent;
            @extend .icon;
            &:first-of-type {
                &:before {
                    content: "\E962";
                    color: $secondary-2;
                    @include icon-glow($secondary-2);
                }
                &:hover {
                    &:before {
                        color: lighten($secondary-2, 5%);
                    }
                }
            }
            &:last-of-type {

                &:before {
                    content: "\E961";
                    color: $secondary-3;
                    @include icon-glow($secondary-3);
                }
                &:hover {
                    &:before {
                        color: lighten($secondary-3, 5%);
                    }
                }
            }
        }
    }
}

.close-button {
    &:before {
        color: $primary-color-1;
        font-size: 2em;
        //content: "\e961" !important;
    }
}

.btn-text {
    @include font-family-custom();
    @include font-style(1.1em, 400, $primary-color-5);
}

.hidden-text {
    display: none !important;
}

.dropdown {
    position: relative;
    display: inline-block;
    .dropdown-toggle {
        color: $primary-color-1;
        text-transform: capitalize;
        white-space: nowrap;
        padding: 0 5rem 0 1rem;
        cursor: pointer;
        /*
        background-image: url('/assets/images/dropdown.svg');
        background-position: center right 20px;
        background-repeat: no-repeat;
        background-size: 10px 10px;
        */
        &::after {
            content: '';
            font-size: 2rem;
            display: inline-block;
            width: 0;
            height: 0;
            vertical-align: baseline;
        }
    }
    .dropdown-list {
        background-color: $primary-color-3;
        position: absolute;
        padding: 0;
        min-width: 12rem;
        flex-direction: column;
        z-index: 25;
        animation: .15s linear fade-in;
        animation-fill-mode: forwards;
        display: none;
        opacity: 0;
        visibility: hidden;
        @include shadow;
    }
    &:hover > .dropdown-list {
        display: flex !important;
        opacity: 1;
        visibility: visible;
    }
    .dropdown-link {
        display: block;
        text-align: left;
        @include font-family-custom();
        @include font-style(1.2em, 700, $primary-color-1);
        padding: 1rem;
        border-bottom: 1px solid fade-out($primary-color-1, .95);
        &:hover {
            background-color: lighten($primary-color-3, 2.5%);
            color: $accent-1-text;
        }
        &:before {
            color: $accent-1;
        }
        &.i-health {
            &:before {
                font-size: 1.5rem;
            }
        }
    }
    /* sub menu */
    .dropdown-list {
        .dropdown-list {
            top: 0;
            left: 100%;
            z-index: 25;
        }
        .dropdoen-item {
            &:hover > .dropdown-list {
                display: flex !important;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .dropdown {
        .dropdown-toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &:hover > .dropdown-list {
            display: flex !important;
            opacity: 1;
            visibility: visible;
        }
    }
}

/* loading overlay */

.loading {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 20rem;
        overflow: hidden;
        display: block;
        background-color: fade-out($primary-color-5, .35);
        background-image: url('/assets/images/loading-2.svg');
        background-repeat: no-repeat;
        background-position: center 10rem;
        background-size: 10rem auto;
        z-index: 1;
    }
}

.is-hidden {
    display: none;
}

/* tooltips */

.w-tooltips {
    font-size: 2.5rem;
    padding: 0;
    margin: 0;
    color: fade-out(red, .65);
    margin-top: -1.2rem;
    @include transition();
    position: relative;
    background: transparent;
    &::before {
        color: $primary-color-3;
        font-size: 1.75rem;
    }
    .tooltips {
        position: absolute;
        right: -23.5rem;
        top: calc(50% + 0.325rem);
        left: auto;
        transform: translateY(-50%);
        width: 20rem;
        background-color: darken($primary-color-1, 5%);
        padding: 2rem;
        @include shadow;
        @include font-style(1.2rem, 500, $primary-color-5);
        text-align: left;
        z-index: 20;
        display: none;
        animation: .125s linear fade-in;
        animation-fill-mode: forwards;
        .w-form & {
            left: 3rem;
            width: 30rem;
        }
        .form-group & {
            width: 20rem;
        }
        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            left: -5px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 5px 0;
            border-color: transparent darken($primary-color-1, 5%) transparent transparent;
        }
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            left: -15px;
            top: 0;
            background-color: transparent;
            height: 100%;
            width: 15px;
        }
        &.bottom {
            transform: translateY(0);
            transform: translateX(-50%);
            top: 2.5rem;
            left: 50%;
            right: auto;
            &::before {
                left: calc(50% - 3px);
                top: -7px;
                transform: translateY(0);
                transform: translateX(-50%);
                transform: rotate(90deg);
            }
        }
        span {
            //color: $dark-gray;
        }
    }
    &:hover {
        //color: $white;
    }
    &:hover .tooltips {
        display: block;
    }
}

/* specific accent color */
/*
.siteConfig, .ads_setups, .ads_setup_edit, ._ad_units {
    .section-title {
        &:before {
            color: $accent-2;
        }
    }
    .sub-header {
        label {
            color: $accent-2;
        }
        select {
            background-image: url(/assets/images/dropdown-bg-2.svg) !important;
        }
        select, .dropdown-toggle {
            background-color: fade-out($primary-color-5, .5);
            border: 1px solid fade-out($accent-2, .75);
            &:hover {
                border: 1px solid fade-out($accent-2, .5);
            }
        }

    }
    .prop-dropdown {
        .btn {
            background-color: fade-out($primary-color-5, .75) !important;
        }
    }
    .btn:not(.large) {
        background-color: $accent-2;
        color: $primary-color-3;
        &:before {
            color: $primary-color-3;
        }
        &:hover {
            background-color: darken($accent-2, 20%);
        }
    }

    .pagination {
        a {
            background-color: $accent-2 !important;
            color: $primary-color-1;
            &:hover {
                background-color: darken($accent-2, 20%);
            }
            &.active {
                color: $primary-color-1 !important;
                background-color: transparent !important;
            }
        }
    }
    .btn {
        &.highlight {
            background-color: $accent-2;
            &:hover {
                background-color: lighten($accent-2, 20%);
            }
        }
    }
    .w-form .form-header {
        color: $accent-2 !important;
    }
    .list-results thead tr {
    }
    .list-results thead {
        span {
            &.icon:before {
                color: $accent-2;
            }
        }
    }
    .w-form {
        .form-header {
            .form-title {
                color:  $accent-2;
                &:before {
                    color: $accent-2;
                }
            }
            .close-button {
                &:before {
                    color: $accent-2;
                }

            }
        }
        .form-body {
            border-top: 0;
            &.full-width {
            }
        }
        .form-footer {
        }
        .input-text {
            &:focus {
                border: 1px solid fade-out($accent-2, .25);
            }
        }
        select {
            background-image: url(/assets/images/dropdown-bg-2.svg) !important;
        }
        .border-top {
        }

        .form-footer {
        }
        input[type="checkbox"]:not(:checked) + label
        {
            color: fade-out($primary-color-1, .45) !important;
        }
        input[type="checkbox"]:checked + label {
            color: $primary-color-1;
        }
        input[type="checkbox"] + label:before
        {
            color: $accent-2;
        }
    }
    .filter-keyword-wrap .w-search-feild:before {
        color: $accent-2;
    }
    .list-results thead .list-sort-none:after {
        color: $accent-2;
    }
    .multi + div .dropdown-toggle {
        background-image: url('/assets/images/multi-bg-2.svg') !important;
    }
}
*/



/* all sites logo */
/*
#menu_property {
    .dropdown-list {
        display: flex;
        flex-wrap: wrap;
        min-width: 40rem;
        height: 50rem;
        .dropdown-item {
            a {
                margin-top: 0 !important;
            }
        }
    }
    .dropdown-item a, &.main-nav-item {
        background-repeat: no-repeat;
        background-position: 10px center ;
        background-size: auto 30%;
    }
    &.main-nav-item {
        background-size: auto 30%;
    }
    .pr-tsam_therichest a, &.pr-tsam_therichest  {
        background-image: url(/assets/images/logos/TR.svg);
    }
    .pr-tsam_thetalko a, &.pr-tsam_thetalko  {
        background-image: url(/assets/images/logos/TT.svg);
    }
    .pr-tsam_screenrant a, &.pr-tsam_screenrant {
        background-image: url(/assets/images/logos/SR.svg);
    }
    .pr-tsam_thesportster a, &.pr-tsam_thesportster {
        background-image: url(/assets/images/logos/TS.svg);
    }
    .pr-tsam_thegamer a, &.pr-tsam_thegamer {
        background-image: url(/assets/images/logos/TG.svg);
    }
    .pr-tsam_thethings a, &.pr-tsam_thethings {
        background-image: url(/assets/images/logos/TH.svg);
    }
    .pr-tsam_cbr a, &.pr-tsam_cbr {
        background-image: url(/assets/images/logos/CB.svg);
    }
    .pr-tsam_thequiz a, &.pr-tsam_thequiz {
        background-image: url(/assets/images/logos/TQ.svg);
    }
    .pr-tsam_babygaga a, &.pr-tsam_babygaga {
        background-image: url(/assets/images/logos/BG.svg);
    }
    .pr-tsam_hotcars a, &.pr-tsam_hotcars {
        background-image: url(/assets/images/logos/HC.svg);
    }
    .pr-tsam_thetravel a, &.pr-tsam_thetravel {
        background-image: url(/assets/images/logos/TV.svg);
    }
    .pr-tsam_moms a, &.pr-tsam_moms {
        background-image: url(/assets/images/logos/MM.svg);
    }
    .pr-tsam_therecipe a, &.pr-tsam_therecipe {
        background-image: url(/assets/images/logos/RE.svg);
    }
    .pr-tsam_gamerant a, &.pr-tsam_gamerant {
        background-image: url(/assets/images/logos/GR.svg);
    }
    .pr-tsam_makeuseof a, &.pr-tsam_makeuseof {
        background-image: url(/assets/images/logos/MUO.svg);
    }
}
*/

.full-icon {
    background-repeat: no-repeat;
    background-position: 10px center ;
    background-size: auto 50%;
    &.i-TS {
        background-image: url(/assets/images/logos/TS.svg) !important;
    }
    &.i-TR {
        background-image: url(/assets/images/logos/TR.svg) !important;
    }
    &.i-RE {
        background-image: url(/assets/images/logos/RE.svg) !important;
    }
    &.i-TG {
        background-image: url(/assets/images/logos/TG.svg) !important;
    }
    &.i-CBR, &.i-CBRF {
        background-image: url(/assets/images/logos/CBR.svg) !important;
    }
    &.i-CB {
        background-image: url(/assets/images/logos/CB.svg) !important;
    }
    &.i-SR {
        background-image: url(/assets/images/logos/SR.svg) !important;
    }
    &.i-TV {
        background-image: url(/assets/images/logos/TV.svg) !important;
    }
    &.i-MM {
        background-image: url(/assets/images/logos/MM.svg) !important;
    }
    &.i-TT {
        background-image: url(/assets/images/logos/TT.svg) !important;
    }
    &.i-BG {
        background-image: url(/assets/images/logos/BG.svg) !important;
    }
    &.i-HC {
        background-image: url(/assets/images/logos/HC.svg) !important;
    }
    &.i-TH {
        background-image: url(/assets/images/logos/TH.svg) !important;
    }
    &.i-RT {
        background-image: url(/assets/images/logos/RT.svg) !important;
    }
    &.i-GR {
        background-image: url(/assets/images/logos/GR.svg) !important;
    }
    &.i-MUO {
        background-image: url(/assets/images/logos/MUO.svg) !important;
    }
    &.i-VN {
        background-image: url(/assets/images/logos/VN.svg) !important;
    }
    &.i-CL {
        background-image: url(/assets/images/logos/CL.svg) !important;
    }
    &.i-AP {
        background-image: url(/assets/images/logos/AP.svg) !important;
    }
    &.i-HTG {
        background-image: url(/assets/images/logos/HTG.svg) !important;
    }
    &.i-WM {
        background-image: url(/assets/images/logos/MW.svg) !important;
    }
    &.i-SF {
        background-image: url(/assets/images/logos/SF.svg) !important;
    }
    &.i-PN {
        background-image: url(/assets/images/logos/PN.svg) !important;
    }
    &.i-XDA {
        background-image: url(/assets/images/logos/XDA.svg) !important;
    }
    &.i-GL {
        background-image: url(/assets/images/logos/GL.svg) !important;
    }
    &.i-TW.i-TW {
        display: none !important;
    }
    &.i-PN {
        background-image: url(/assets/images/logos/PN.svg) !important;
    }
    &.i-XDAF {
        background-image: url(/assets/images/logos/XDA.svg) !important;
    }
    &.i-DS {
        background-image: url(/assets/images/logos/DS.svg) !important;
    }
    &.i-TPS {
        background-image: url(/assets/images/logos/TPS.svg) !important;
    }
    &.i-PL {
        background-image: url(/assets/images/logos/PL.svg) !important;
    }
    &.i-GMS{
        background-image:url(/assets/images/logos/GMS.svg) !important;
    }
    &.i-FF{
        background-image:url(/assets/images/logos/FF-light.svg) !important;
    }
    &.i-FFC{
        background-image:url(/assets/images/logos/FFC-light.svg) !important;
    }
    &.i-FLW{
        background-image:url(/assets/images/logos/FLW.svg) !important;
    }
    &.i-FTT{
        background-image:url(/assets/images/logos/FTT.svg) !important;
    }
    &.i-TCB{
        background-image:url(/assets/images/logos/TCB.svg) !important;
    }
    &.i-TIF{
        background-image:url(/assets/images/logos/TIF.svg) !important;
    }
    &.i-VC{
        background-image:url(/assets/images/logos/VC.svg) !important;
    }
    &.i-BYB {
        background-image:url(/assets/images/logos/BYB.svg) !important;
    }
    &.i-HG {
        background-image:url(/assets/images/logos/HG.svg) !important;
    }
}

footer {
    border-top: 1px solid darken($primary-color-1, 10%);
    padding: 1rem;
    width: 100%;
}

.login + footer, .home + footer {
    position: absolute;
    bottom: 0;
    left: 0;
}

.static {
    padding: 2rem;
    text-align: left;
    .btn {
        width: 15rem;
    }
    a {
        color: $accent-1;
    }
    p {
        color: $primary-color-5;
        text-align: left;
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }
    strong {
        margin-top: 1rem;
    }
}

body {
    &.ads {
        .w-site-msg {
            background: rgba(238,186,21,1);
            background: linear-gradient(90deg, rgba(238,186,21,.8) 0%, rgba(238,186,21,.8) 15%, rgba(238,186,21,0.1) 50%, rgba(238,186,21,0.1) 75%, rgba(238,186,21,0.1) 100%);
            .site-msg {
                &:before {
                    content: 'ads';
                }
            }
        }
    }
    &.qa1 {
        .w-site-msg {
            background-color: #FB843F;
            background: linear-gradient(90deg, rgba(251,132,63,.9) 0%, rgba(251,132,63,.9) 15%, rgba(251,132,63,0.1) 50%, rgba(251,132,63,0.1) 75%, rgba(251,132,63,0.1) 100%);
            .site-msg {
                &:before {
                    content: 'qa1';
                }
            }
        }
    }
    &.qa2 {
        .w-site-msg {
            background-color: #FB843F;
            background: linear-gradient(90deg, rgba(251,132,63,.9) 0%, rgba(251,132,63,.9) 15%, rgba(251,132,63,0.1) 50%, rgba(251,132,63,0.1) 75%, rgba(251,132,63,0.1) 100%);
            .site-msg {
                &:before {
                    content: 'qa2';
                }
            }
        }
    }
    &.qa3 {
        .w-site-msg {
            background-color: #FB843F;
            background: linear-gradient(90deg, rgba(251,132,63,.9) 0%, rgba(251,132,63,.9) 15%, rgba(251,132,63,0.1) 50%, rgba(251,132,63,0.1) 75%, rgba(251,132,63,0.1) 100%);
            .site-msg {
                &:before {
                    content: 'qa3';
                }
            }
        }
    }
    &.qa4 {
        .w-site-msg {
            background-color: #FB843F;
            background: linear-gradient(90deg, rgba(251,132,63,.9) 0%, rgba(251,132,63,.9) 15%, rgba(251,132,63,0.1) 50%, rgba(251,132,63,0.1) 75%, rgba(251,132,63,0.1) 100%);
            .site-msg {
                &:before {
                    content: 'qa4';
                }
            }
        }
    }
    &.qa5 {
        .w-site-msg {
            background-color: #FB843F;
            background: linear-gradient(90deg, rgba(251,132,63,.9) 0%, rgba(251,132,63,.9) 15%, rgba(251,132,63,0.1) 50%, rgba(251,132,63,0.1) 75%, rgba(251,132,63,0.1) 100%);
            .site-msg {
                &:before {
                    content: 'qa5';
                }
            }
        }
    }
    &.qa6 {
        .w-site-msg {
            background-color: #FB843F;
            background: linear-gradient(90deg, rgba(251,132,63,.9) 0%, rgba(251,132,63,.9) 15%, rgba(251,132,63,0.1) 50%, rgba(251,132,63,0.1) 75%, rgba(251,132,63,0.1) 100%);
            .site-msg {
                &:before {
                    content: 'qa6';
                }
            }
        }
    }
    &.qa7 {
        .w-site-msg {
            background-color: #FB843F;
            background: linear-gradient(90deg, rgba(251,132,63,.9) 0%, rgba(251,132,63,.9) 15%, rgba(251,132,63,0.1) 50%, rgba(251,132,63,0.1) 75%, rgba(251,132,63,0.1) 100%);
            .site-msg {
                &:before {
                    content: 'qa7';
                }
            }
        }
    }
    &.qa8 {
        .w-site-msg {
            background-color: #FB843F;
            background: linear-gradient(90deg, rgba(251,132,63,.9) 0%, rgba(251,132,63,.9) 15%, rgba(251,132,63,0.1) 50%, rgba(251,132,63,0.1) 75%, rgba(251,132,63,0.1) 100%);
            .site-msg {
                &:before {
                    content: 'qa8';
                }
            }
        }
    }
    &.ads, &.staging, &.qa1, &.qa2, &.qa3, &.qa4, &.qa5, &.qa6, &.qa7, &.qa8 {
        padding-top: 2rem;
        .site-logo {
            top: 2rem !important;
        }
        .prop-dropdown {
            top: 0 !important;
        }
        .main-nav {
            top: 7.5rem !important;
        }
        .main-header {
            top: 2rem;
        }

        .w-site-msg {
            height: 2rem;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 99999;
            background-color: $primary-color-4;
            box-shadow: 0px 3px 7px 2px rgba(0,0,0,0.2);
            .site-msg {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 2rem;
                width: 100%;
                height: 100%;
                &:before {
                    @include font-family-universal();
                    @include font-style(1.3rem, 700, $primary-color-1);
                    text-shadow: 0px 0px 3px rgba(0,0,0,0.5);
                    text-transform: uppercase;
                }
            }
        }
    }
}


