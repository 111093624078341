@media print {
    .main-header, .i-printer, .list-header, .sub-header .w-left strong, .sub-header .w-left .w-toggle, .w-center .w-toggle, .w-center  .i-auto-complete {
        display: none;
    }
    .graph {/*
        box-shadow: none;
        background-color: transparent;
        width: 30% !important;*/
    }
    .graph-header {/*
        display: none;*/
    }
}