/* use if there a lot of column to add a horiz scroll */

.w-list-results {
    display: inline-block;
    width: 100%;
    min-height: 30rem;
    overflow-y: scroll;
    position: relative;
    padding: 0;
    margin-bottom: 2rem;
    &.short {
        width: 50rem;
    }

}

.list-results {
    @include shadow-darker;
    border: 1px solid $border;
    width: calc(100% - 4rem);
    @include general-margin;
    @include shadow;
    border-spacing: 0;
    border-collapse: collapse;
    text-align: left;

    &.half {
        width: 50%;
        min-width: 100rem;
    }
    &.short {
        width: 50rem;
        margin: 0;
    }
    caption {

    }
    thead {
        //border-bottom: 1px solid $border-light;
        tr {
            @include size(auto, 6rem);
            background-color: lighten($primary-color-4, 7%);
            &.regroup {
                height: auto;
                vertical-align: middle;
            }

        }
        th {
            padding: 4rem 2rem 2rem;
            &.w-btn {
                width: 4rem;
            }
            &.short {
                padding: 2rem .5rem ;
            }
            &.align-right {
                text-align: right;
            }
            .taller & {
                height: auto;
            }
            &.narrow {
                width: 7rem;
            }

        }
        &.condensed {
            tr {
                height: auto;
            }
            th {
                padding: 1.75rem 2rem;
                span {
                    height: auto;
                }
            }
        }
        strong {
            @include font-style(.9em, 700, $primary-color-1);
            border-bottom: 1px solid $border-light;
            text-transform: uppercase;
            display: block;
            padding-bottom: .5rem;
            margin-top: 1.5rem;
        }
        span {
            @include font-family-custom();
            @include font-style(1em, 700, $primary-color-1);
            text-transform: uppercase;
            position: relative;
            display: inline-block;
            height: 2rem;
            white-space: nowrap;
            &:before {
                font-size: 2rem;
                color: $accent-1;
                margin-right: .5rem;
            }
            &.i-position {
                &:before {
                    color: $primary-color-1;
                    font-size: 1.25rem;
                }
            }
            &.i-podium, &.i-search2 {
                &:before {
                    font-size: 1.35em;
                }
            }
            &.i-url, &.i-click {
                &:before {
                    font-size: 1.5em;
                }
            }
            &.align-right {
                text-align: right;
            }
        }
        .total {
            @include font-style(1.3rem, 700, $primary-color-1);
        }       
        .icon {
            cursor: pointer;
            &:after {
                display: inline-block;
                font-size: 2rem;
                position: absolute;
                //@include size(1rem, 1rem);
                transform-origin: center center;
                color: $accent-1;
            }
        }
        .list-sort-none {
            &::after {
                content: '\e95';
                top: auto;
                bottom: -1.25rem;
                left: -.5rem;
            }
        }
        .list-sort-desc {
            &::after {
                content: '\e8ea';
                top: 3rem;
                left: -.5rem;
            }
        }
        .list-sort-asc {
            &::after {
                content: '\e8ea';
                top: 1.125rem;
                left: 5rem;
                transform: rotate(-180deg) translateX(4.5rem);
            }
        }

    }
    tbody {
        tr {
            background-color: lighten($primary-color-4, 14%);
            border-bottom: 1px solid lighten($primary-color-3, 3.5%);
            &:nth-child(even) {
                background-color: lighten($primary-color-4, 11%);
            }
            &:hover {
                background-color: lighten($primary-color-4, 18%);
                .i-move {
                    &:before {
                        color: $primary-color-1;
                    }
                }
            }
            &.is-checked {
                background-color: darken($accent-1, 20%);
                span {
                    color: lighten($accent-1, 30%) !important;
                }
            }
            &.total {
                border-top: 1px double $primary-color-1;
                background-color: #181d24;
                td {
                    padding: 1.2rem 2rem;
                    span {
                        @include font-style(1.4rem, 700, $primary-color-1);
                    }
                }
            }
        }
        td {
            padding: .5rem 2rem;
            &.short {
                padding: 2rem .25rem;
            }
            span.line-clamp {
                @include line-clamp(3);
                max-width: 50rem;
            }
            span {
                &:before {
                    margin-right: .5rem;
                }
            }
        }
        .i-link {
            white-space: nowrap;
            cursor: pointer;
            display: flex;
            justify-content: start;
            align-items: center;
            &:after {
                margin-left: .5rem;
                color: $accent-2;
                font-size: 1.25rem;
            }
            &:hover {
                color: $accent-2;
            }

        }
        .i-move {
            &:before {
                color: fade-out($primary-color-1, .5);
                font-size: 1.5rem;
            }

        }
        .i-dropdown-simple {
            font-size: 1.5rem;
            &.is-open {
                transform: rotate(180deg);
            }
        }
        .no-wrap {
            white-space: nowrap;
        }
        .flex {
            display: flex;
            align-items: center;
            .btn {
                &:nth-of-type(2) {
                    margin-left: .25rem;
                }
            }
        }
        .td-img {
            padding: 0 2rem 0 0;
        }
        .w-user-img {
            margin-right: 1rem;
            @include size(5rem,5rem);
            overflow: hidden;
            img {
                width: auto;
                height: 5rem;
            }
        }
        .cap {
            text-transform: capitalize;
        }
        .full-icon {
            padding-left: 3rem;
            background-size: contain;
            height: 2rem;
            width: 2rem;
        }
        span {
            @include font-family-custom();
            @include font-style(1.2rem, 400, $primary-color-1);
            display: block;
            &.align-right {
                text-align: right;
            }
            a {
                @include font-family-custom();
                @include font-style(1.2rem, 400, $primary-color-5);
                &:after {
                    font-size: 1.4rem;
                    margin-left: .5rem;
                    color: $accent-1;
                }
                &:hover {
                    color: $accent-1;
                }
            }
            &.indic-very-good,
            &.indic-good,
            &.indic-bad,
            &.indic-very-bad {
                white-space: nowrap;
                font-weight: 900;
                &:before {
                    display: inline-block;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    margin-right: .5rem;
                }
            }
            &.indic-very-good {
                color: #33D075;
                &:before {
                    border-width: 0 6px 10.4px 6px;
                    border-color: transparent transparent #33D075 transparent;
                }
            }
            &.indic-good {
                color: #83cdb1;
                &:before {
                    border-width: 0 6px 10.4px 6px;
                    border-color: transparent transparent #83cdb1 transparent;
                }
            }
            &.indic-bad {
                color: #fc6f35;
                &:before {
                    border-width: 10.4px 6px 0 6px;
                    border-color: #fc6f35 transparent transparent transparent;
                }
            }
            &.indic-very-bad {
                color: #ff305c;
                &:before {
                    border-width: 10.4px 6px 0 6px;
                    border-color: #ff305c transparent transparent transparent;
                }
            }
            &.status {
                display: flex;
                align-items: center;
                @extend .icon;
                &:before {
                    font-size: 2rem;
                    width: 2rem;
                    margin: 0;
                    margin-right: .5rem;
                    display: inline-flex !important;
                    justify-content: center;
                }
            }
            &.status-pending {
                &:before {
                    content: '\e98d';
                    color: $pending;
                }
            }
            &.status-done {
                &:before {
                    content: '\e939';
                    color: $secondary-4;
                }
            }
            &.status-error {
                &:before {
                    content: '\e926';
                    color: $secondary-3;
                    font-size: 3.5rem;
                }
            }
            &.status-in-progress {
                &:before {
                    content: '\e9a1';
                    color: $complete;
                    font-size: 2.5rem;
                    margin-top: 0.5rem;
                }
            }
        }
        .w-img {
            @include size(3rem, 3rem);
            border-radius: 50%;
            overflow: hidden;
        }
        img {
            display: block;
            width: 100%;
            height: auto;
        }
        .total {
            @include font-style(1.2rem, 700, $primary-color-5);
        }
        .group {
            background-color: lighten($primary-color-3, 28%);
            border-left: 1px solid $border;
            &.first {
                border-top: 1px solid $border;
            }
            &.single {
                border-bottom: 1px solid $border;
                border-top: 1px solid $border;
            }
        }
        .is-selected {
            background-color: fade-out($accent-2, .7) !important;
            &:hover {
                background-color: fade-out($accent-2, .7) !important;
            }
        }
        .w-btn {
            padding-left: 0;
        }
    }
    .with-tooltips {
        display: flex;
        align-items: center;
        padding: 0rem 2rem 0rem;
        position: relative;
        .w-tooltips-adv {
            margin-left: -.5rem !important;
            position: absolute;
            right: .25rem;
            margin: 0;
            .tooltips-adv {
                height: 10rem;
                &:before {
                    border-color: transparent transparent lighten($primary-color-4, 25%) transparent;
                }
            }

        }
    }
    tfoot {

    }
    .no-wrap {
        white-space: nowrap;
    }
    .align-right {
        text-align: right;
        .btn {
            margin: 0 auto;
        }
    }
    .align-center {
        text-align: center;
        .btn {
            margin: 0 auto;
        }
    }
    .cursor {
        cursor: pointer;
    }
    .border-right {
        border-right: 1px solid $border;
    }
    .brands {
        span {
            &:before {
                color: $primary-color-3;
                font-size: 1.5em;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 1rem;
            }
        }
    }
    &.first-column-fixed {
        thead,
        tbody {
            tr {
                th:first-of-type,
                td:first-of-type {
                    position: sticky;
                    left: 0;
                    z-index: 1;
                    border-right: 0px solid $border;
                }
            }
        }
        thead {
            tr {
                th {
                    background-color: lighten($primary-color-4, 7%);
                    &.rev-sheet-advertiser-total {
                        border-left: 1px solid;
                        border-right: 1px solid;
                        background-color: darken($primary-color-4, 0.3%);
                    } 
                }
            }
        }
        tbody {
            tr {
                border-bottom: 0px solid $primary-color-4;
                td {
                    background-color: lighten($primary-color-4, 14%);
                    &:first-of-type {
                        background-color: lighten($primary-color-4, 3%);
                    }
                    &:nth-of-type(4n+1) {
                        border-right: 0px solid $primary-color-3;
                    }   
                    &.rev-sheet-advertiser-total {
                        border-right: 1px solid;
                        background-color: lighten($primary-color-4, 3%);

                    }                                     
                }
                &:hover {
                    td,
                    td:first-of-type {
                        background-color: $hover;
                    }
                }                
                &.total {
                    td,
                    td:first-of-type {
                        background-color: $primary-color-3;
                    }
                }
            }
            tr:nth-child(even) {
                td {
                    background-color: lighten($primary-color-4, 11%);
                    &:first-of-type {
                        background-color: lighten($primary-color-4, 3%);
                    }
                    &.rev-sheet-advertiser-total {
                        border-right: 1px solid;
                        background-color: darken($primary-color-4, 0.3%);
                    }                                          
                }
                &:hover {
                    td,
                    td:first-of-type {
                        background-color: $hover;
                    }
                }                              
            }         
        }
        
    } 
    #revenueSheet_template & {
        .border-left {
            border-left: 1px solid $border;
        }
    }       
}

.info-results {
    text-align: left;
    font-size: .85em;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    border-bottom: 1px solid $border;
    position: absolute;
    left: 2rem;
    right: 2rem;
    top: 0;
    text-align: left;
    .short & {
        left: 0;
        right: 0;
    }
    .taller & {
        padding: 1.5rem 0 1.5rem 8rem;
    }
    span {
        display: inline-block;
        margin-right: 1rem;
        padding: .25rem .25rem .125rem 0;
        @include font-family-custom();
        @include font-style(1rem, 700, $primary-color-1);
        span {
            font-weight: 400;
            margin-right: 0rem;
        }
    }
}
.filter-preset {
    display: none;
}
.w-show-hide-columns-btn {
    position: absolute;
    z-index: 20;
    right: 45px;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
}
/* sticky col */

.w-sticky-col {
    width: calc(100% - 13rem);
    overflow-x: scroll;
    margin-left: 13rem;
    margin-right: 0;
    overflow-y: visible;
    padding: 0;
    margin-bottom: 4rem;
    position: static;
    .list-results {
        margin: 0;
        border: 0;
    }
    tr {
        th {
            &:first-of-type {
                position: absolute;
                opacity: 0;
            }
        }
    }
    .stick-col {
        position: absolute;
        width: 7rem;
        height: 15px;
        left: 189px;
        top: auto;
        //padding-bottom: 6px;
        text-align: right;
        border-right: 1px solid lighten($primary-color-3, 10%);
        border-bottom: 1px solid darken($secondary-color-1, 25%);
        background-color: $primary-color-3;
        box-shadow: inset -5px 0px 13px -11px rgba(0,0,0,0.25);
        @include transition();
    }
    &.is-open {
        .stick-col {
            left: 58px
        }
    }
    .ad.revenue.report &{
        width: calc(100% - 10rem);
        margin-left: 10rem;

        .stick-col {
            padding: 0.5rem;
        }
        .list-results tbody td {
            padding: .5rem 1rem;
        }
    }
}

/* header list */

.list-header {
    @include general-margin;
    margin-bottom: 2rem;

    .w-left {
        .btn {
            margin-right: 1rem !important;
        }

    }
    .w-right {
        & > .btn {
            margin-left: 1rem;
        }
    }
    &.margin-top {
        margin-top: 2rem;
    }
    &.short {
        width: 50rem;
        margin-left: auto;
        margin-right: auto;
    }
    .w-left div,
    .w-right div {
        label {
            @include font-style(1rem, 700, $accent-1-text);
            position: absolute;
            margin: .65rem 0 0 1rem;
            z-index: 20;
            pointer-events: none;
            text-transform: uppercase;

        }
        select, .dropdown-toggle {
            @include size(19rem, 4rem);
            text-align: left;
            @include font-style(1.2rem, 700, $primary-color-1);
            background-color: fade-out($primary-color-5, .5);
            border: 1px solid fade-out($accent-1, .5);
            box-shadow: none;
            //border: 1px solid fade-out($accent-1, .5);
            background-image: url('/assets/images/dropdown-bg.svg');
            background-size: 24px 24px;
            background-position: 95% center;
            background-repeat: no-repeat;
            @include shadow;
            margin: 0;
            padding: 1.5rem 3rem 0 1rem;
            text-transform: capitalize;
            cursor: pointer;
            box-sizing: border-box;
            border-radius: $corner;
            option {
                @-moz-document url-prefix() {
                    font-size: 1.2rem;
                    background-color: $primary-color-3;
                    margin-bottom: .25rem;
                }

            }
            &:hover {
                border-color: $accent-1;
            }
        }
    }
}

/* ad revenue */

.list-results {
    thead {
        .list-header-section {
            background-color: $primary-color-3;
            .secondary-th {
                padding: 2rem 2rem 1rem;
                &:nth-of-type(4n+1) {
                    border-right: 1px solid darken($secondary-color-1, 25%);
                }
                &:last-of-type {
                    border: 0;
                }
            }
        }
        .w-header-ad {
            padding: 1rem;
            border-right: 1px solid darken($secondary-color-1, 25%);
            &:last-of-type {
                border-right: 0;
            }
        }
        .header-ad {
            display: grid;
            grid-template-rows: 1fr 1fr ;
            grid-template-columns: 1fr 1fr;
            gap: 10px;

            div {
                .header-ad-label {
                    @include font-family-custom();
                    @include font-style(1rem, 700, $secondary-color-1);
                    display: block;
                    text-shadow: 0px 0px 1px black;
                    margin-bottom: -8px;
                }
                .header-ad-value {
                    @include font-family-custom();
                    @include font-style(1.8rem, 700, $primary-color-1);
                    display: block;
                }
            }
            .header-ad-type {
                grid-column: 1 / span 1;
                span {
                    @include font-family-custom();
                    @include font-style(1.8rem, 700, $accent-1-text);
                }
            }
            .header-ad-revenue {
                grid-column: 2 / span 1;
                text-align: right;
            }
            .header-ad-avg {
                grid-column: 2 / span 1;
                grid-row: 2 / span 1;
                text-align: right;
            }
            .header-ad-revenue-percentage {
                grid-row: 2 / span 1;
                grid-column: 1 / span 1;
                .header-ad-value {
                    color: $accent-2;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                &:nth-of-type(4n+1) {
                    border-right: 1px solid $primary-color-3;
                }
                &:last-of-type {
                    border: 0;
                }
            }
        }
    }
}

._ad_revenue {
    .list-results {
        thead {
            .list-header-section {
                .secondary-th {
                    padding: 2rem 1rem 1rem;
                }
            }
        }
        tbody {
            td {
                padding: .5rem 1rem;
            }
        }
    }
}


/* log list */

#logs {
    .list-results {
        .btn.i-back {
            @include size(2rem,2rem);

        }
        .highlight {
            background-color: $report-2;
            span {
                color: darken($report-2, 50%);

            }
        }
    }
}


/* searh feild */

.filter-keyword-wrap {
    .w-search-feild {
        position: relative;
        &:before {
            font-size: 1.5em;
            color: $accent-1;
            @include icon-glow($accent-1);
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
        }
    }
    input[type="input"] {
        @include font-family-custom();
        @include font-style(1.2em, 400, $primary-color-1);
        @include size(22rem, 3rem);
        padding: 0 1.25rem 0 .5rem;
        padding-left: 3rem;
        padding-right: 2.5rem;
        background-color: fade-out($primary-color-5, .5);
        border: 1px solid $border;
        outline: 0;
        &:focus {
            border: 1px solid fade-out($primary-color-1, .5);
            box-shadow: none;
            outline: 0;
            background-color: $primary-color-5;

        }
    }
    .close-btn {
        position: absolute;
        right: .5rem;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        &:before {
            font-size: 1.75em;
            color: $accent-2;
            @include icon-glow($accent-2);
        }
        &.is-showing {
            display: inline-block;
        }
    }
}

/* Pagination */

.pagination {
    display: flex;
    //margin-right: -.5rem;
    ul {
        display: flex;
    }
    a {
        @extend .btn;
        color: $primary-color-1;
        //border: 1px solid $border;
        background-color: $accent-1 !important;
        min-width: 3rem;
        margin: 0 .25rem;
        padding: .5rem 1rem .5rem;
        display: block;
        cursor: pointer;
        @include transition;
        display: flex;
        align-items: center;
    }
    .active {
        background: none !important;
        border: 0;
        pointer-events: none;
        color: $primary-color-1;
        box-shadow: none;
        font-size: 1.4rem;
    }
    span {
        a {
            padding: .5rem 1rem .5rem 1rem;
        }
    }
}

/* Specific */


#tasks {
    .list-header {
        .w-left {
            flex-wrap: nowrap;
        }
    }
}

/* auto refresh button */

@mixin inactiveMixin {
    content: "";
    position: absolute;
    display: block;
}

@mixin beforeAnimation {
    transition: .2s cubic-bezier(.24, 0, .5, 1);
}

@mixin afterAnimation {
    transition: .35s cubic-bezier(.54, 1.60, .5, 1);
}

// Mobile Toggle Switch

.toggleWrapper {
    position: absolute;
    left: 3rem;
    top: 1rem;
    z-index: 13;
    &:before {
        content: '';
    }
    input {
        &.mobileToggle {
            opacity: 0; // hides checkbox
            position: absolute;
            & + label {
                position: relative;
                display: inline-block;
                user-select: none;
                transition: .4s ease;
                -webkit-tap-highlight-color: transparent;
                height: 30px;
                width: 28px;
                border: 1px solid $primary-color-5;
                background-color: fade-out(#fff, .5);
                border-radius: 60px;
                @extend .icon;
                &:before {
                    @include inactiveMixin;
                    @include beforeAnimation;
                    background: white;
                    height: 30px;
                    width: 51px;
                    top: 0;
                    left: 0;
                    border-radius: 30px;
                }
                &:after {
                    @include inactiveMixin;
                    @include afterAnimation;
                    content: '\e99c';
                    color: #c8c8c8;
                    font-size: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: white;
                    height: 28px;
                    width: 28px;
                    top: 0;
                    left: 0px;
                    border-radius: 60px;
                }
            }
            // When Active
            &:checked {
                & + label:before {
                    background: $secondary-4; // Active Color
                    transition: width .2s cubic-bezier(0, 0, 0, .1);
                }
                & + label:after {
                    left: 54px - 30px;
                    animation: 3s infinite rotating linear;
                    color: $secondary-4;
                }
            }
        }
    }
    input[type="checkbox"]:checked+label:before {
        transform: scale(1);
        z-index: 0;
    }
}

.w-list-results {
    &.taller {
        thead {
            tr {
                height: auto;
                th {
                    padding: 6rem 2rem 2rem;
                    position: relative;
                }
            }
        }
    }
}