.list-action {
    border-spacing: 0;
    border-collapse: collapse;
    width: calc(100% - 4rem);
    margin: 0 auto 2rem;

    .w-form & {
        margin: 0 1rem 2rem;
    }

    &.full-width {
        width: calc(100% - 2rem);
        margin: 0 1rem 2rem;
    }

    span, h3 {
        @include font-family-custom();
        @include font-style(1em, 700, $primary-color-1);
        text-transform: uppercase;

        &:before {
            color: $accent-1;
            margin-right: .25rem;
        }
    }

    thead {
        tr {
            background-color: lighten($primary-color-4, 15%);

            &:first-of-type {
                border-right: 1px solid $border;
                height: 3rem;

                span {
                    @include font-style(1.2rem, 700, $primary-color-1);

                    &:before {
                        font-size: 1.5rem;
                    }
                }
            }

            th {
                & > div {
                    //width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 1rem;
                }

                border-right: 1px solid $border;

                &:last-of-type {
                    border-right: 1px solid $border;
                }
            }
        }

        th {
            text-align: center;
            padding: .25rem 0;
        }

        .full-icon {
            padding-left: 2.5rem;
            background-size: auto 100%;
            background-position: left center;
        }
    }

    tbody {
        text-align: left;

        #userProfile & {
            text-align: center;
        }

        tr {
            border-bottom: 1px solid lighten($border, 12%);
            background-color: lighten($border, 17%);

            &:nth-of-type(odd) {
                background-color: lighten($border, 19%);
            }

            &:hover {
                background-color: lighten($border, 23%);
            }
        }

        td {
            padding: .75rem .5rem .5rem .5rem;
            border-right: 1px solid lighten($border, 12%);

            &:first-of-type {
                border-left: 1px solid lighten($border, 12%);
            }
        }
    }

    td {
        > div {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
    }

    .align-left {
        text-align: left !important;
    }

    .align-right {
        text-align: left !important;
    }

    button {
        @include size(2.4rem, 2.4rem);
        background-color: lighten($border, 12%);
        color: $primary-color-1;
        border-radius: $corner;
        margin-left: .25rem;

        &:hover {
            background-color: $border;
        }

        &:before {
            width: 2.4rem;
        }

        &.i-check {
            line-height: 2;

            &:before {
                font-size: 1.25rem;
                color: $complete;
            }

        }

        &.i-cancel {
            line-height: 2;

            &:before {
                font-size: 2.5rem;
                color: $error;
            }
        }
    }

    .dropdown-toggle {
        width: auto;
        @include font-family-custom();
        @include font-style(1em, 700, $primary-color-5);
        background-color: transparent;
        background-image: url('/assets/images/multi-bg-red.svg');
        background-size: 25px 25px;
        background-position: center right;
        background-repeat: no-repeat;
        padding: 0 2.5rem 0 1rem;
        text-transform: uppercase;

        &:hover {
            background-color: transparent;
            opacity: .8;
        }
    }

    .dropdown-list {
        top: .5rem;
        right: 0;

        .form-group {
            display: flex;
            flex-wrap: wrap;
            padding-top: 1rem;
        }

        .multi-row {
            flex: 1 50%;
        }

        .form-label {
            pointer-events: auto;
        }
    }

    .form-group {
        margin: 0 auto;
        width: auto;
        display: flex;
        justify-content: center;

        input[type="checkbox"] + label {
            margin-left: -2rem;
        }
    }

    .input-text {
        @include size(5.25rem, 2.4rem);
        text-align: center;
        padding: .5rem;

        &.read-only {
            border-color: transparent;
            background: none;
            pointer-events: none;
            //width: auto;
            text-align: left;
            padding-left: 0;
            @include font-family-custom();
            @include font-style(1em, 700, $primary-color-2);
        }
    }
}

/* specific */


.roles-view {
    width: calc(100% - 2rem);

    thead {
        tr {
            &:last-of-type {
                th {
                    width: 14rem;
                    text-align: left;
                    padding-left: 2.5rem;

                    &:first-of-type {
                        width: 22%;

                        padding-left: .5rem;

                        #userProfile & {
                            text-align: center;
                        }
                    }
                }

                span {
                    position: relative;

                    &:before {
                        position: absolute;
                        left: -2.6rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.75rem;
                        @include size(2.6rem, 2.6rem);
                        color: $primary-color-4;
                        //background-color: $primary-color-3;
                    }
                }
            }
        }
    }
}

._view._roles, ._edit._roles {
    .view-results {
        //margin-bottom: 5rem;
    }

    .form-header {
        .close-button {
            &:before {
                content: '\e961';
                font-size: 2.5rem;
            }
        }
    }

    .form-footer {
        .btn {
            @include size(5rem,5rem);
            background: none;
            @extend .icon;
            box-shadow: none!important;
            text-indent: -5000px;
            span {
                display: none;
            }
            &:before {
                font-size: 4rem;
                text-indent: 0px;
            }
            &:first-of-type {
                margin-right: 1rem;
                &:before {
                    content: "\E961";
                    color: $secondary-3;
                    @include icon-glow($secondary-3);
                }
                &:hover {
                    &:before {
                        color: lighten($secondary-3, 5%);
                    }
                }
            }
            &:last-of-type {
                &:before {
                    content: "\E962";
                    color: $secondary-2;
                    @include icon-glow($secondary-2);
                }
                &:hover {
                    &:before {
                        color: lighten($secondary-2, 5%);
                    }
                }
            }
        }

    }

    .list-action {
        thead {
            tr {
                background-color: lighten($primary-color-4, 5%);

                &:last-of-type {
                    th {
                        //padding: .5rem 0;gi
                        text-align: center;

                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: -3rem;

                        &:before {
                            color: lighten($accent-1, 10%);
                            position: static;
                            font-size: 1.5rem;
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 1rem 2rem ;

                    &:first-of-type {
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;
                        justify-content: space-between;
                    }

                    .w-tooltips-adv {
                        margin-right: 1rem ;
                        .open-button {
                            transform: scale(.75);
                        }

                    }
                }
            }

            td {
                padding: 0 1rem;

                .form-group {
                    height: 2.5rem;
                }
            }
        }

        input[type="checkbox"] + label {
            &:after {
                @include size(1.5rem, 1.5rem);
                top: 0.5rem;
            }

            &:before {
                left: 0.25rem;
                top: 1.25rem;
                font-size: 1.125rem;
            }
        }
    }

    .tooltips-adv {
        &:before {
            border-color: transparent transparent lighten($primary-color-4, 25%) transparent;
        }
    }
}

._edit._roles {
    tbody {
        td {
            padding: .5rem 1rem;
        }
    }
}