$site-padding: 2rem;

$corner: 2px;

$corner2x: 5px;

/* Color */

$primary-color-1: #fff;
$primary-color-2: red;
$primary-color-3: #191D21;
$primary-color-4: #272e3c;
$primary-color-5: #000;

$secondary-color-1: #989898;

//background-color: lighten($primary-color-4, 3%);
//background-color: lighten($primary-color-4, 8%);
//background-color: lighten($primary-color-4, 15%);


$input-bg: lighten($primary-color-4, 25%);

$accent-1: #0069cb;
$accent-2: #FCF370;

$accent-1-text: lighten($accent-1, 20%);
$accent-1-text-1: lighten($accent-1, 20%);

$icon-1: lighten($accent-1, 15%);

$border: darken($primary-color-4, 5%);

$border-light: fade-out($primary-color-1, .9);

$pending: #f59b00;

$secondary-3: #FA4F73;
$secondary-2: #289969;
$secondary-4: #66BA19;
$secondary-5: #F04270;

$report-2: #33D075;
$report-1: #83cdb1;
$report--1: #fc6f35;
$report--2: #ff305c;

$complete: #46C5DB;
$success: #46C5DB;


$section-1: #d7f693;
$section-2: #46C5DB;
$section-3: #87e4ac;
$section-4: #93b3f9;

$success: #07ba6e;
$alert: #d7e410;
$error: #d70848;

$hover: #637494;

$comments: #10c46e;
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #cd201f;
$youtube-top: #e52d27;
$youtube-bottom: #b31217;
$vimeo: #aad450;
$tumblr: #32506d;
$instagram: #517fa4;
$reddit: #0923fb;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;
$feed: #f6921e;
$email: #666;
$comment: #10c46e;
$more: #a0a3a7;