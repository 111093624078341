$iconVersion: "1.1";

@font-face {
    font-family: 'icomoon';
    src:  url('/assets/fonts/icomoon.eot?v=#{$iconVersion}');
    src:  url('/assets/fonts/icomoon.eot?v=#{$iconVersion}#iefix') format('embedded-opentype'),
          url('/assets/fonts/icomoon.woff2?v=#{$iconVersion}') format('woff2'),
          url('/assets/fonts/icomoon.ttf?v=#{$iconVersion}') format('truetype'),
          url('/assets/fonts/icomoon.woff?v=#{$iconVersion}') format('woff'),
          url('/assets/fonts/icomoon.svg?v=#{$iconVersion}#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

%icon {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 0;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon {
    &::after, &::before {
        font-family: 'icomoon';
        display: inline-block;
        vertical-align: middle;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 0;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &.i-keywords:before {
        content: "\e999";
    }
    &.i-group:before {
        content: "\e958";
    }
    &.i-position:before {
        content: "\e990";
    }
    &.i-child:before {
        content: "\e96d";
    }

    &.i-health:before {
        content: "\e975";
    }
    &.i-report:before {
        content: "\e980";
    }
    &.i-help:before {
        content: "\e98b";
    }
    &.i-link:after {
        content: "\e94a";
    }
    &.i-login:before {
        content: "\e913";
    }
    &.i-view:before {
        content: "\e947";
    }
    &.i-logout:before {
        content: "\e904";
    }
    &.i-dropdown-simple:after {
        content: "\e8ea";
    }
    &.i-child:before {
        content: "\E96D";
    }
    &.i-role:before {
        content: "\e948";
    }
    &.i-target:before {
        content: "\e9570";
    }
    &.i-code:before {
        content: "\e976";
    }
    &.i-partnership:before {
        content: "\e977";
    }
    &.i-update:before {
        content: "\e949";
    }
    &.i-geogroup:before {
        content: "\e979";
    }
    &.i-back:before {
        content: "\e8ea";
        transform: rotate(90deg);
    }
    &.i-roi:before {
        content: "\e900";
    }
    &.i-dropdown:after {
        content: "\e901";
    }
    &.i-close:before {
        content: "\e926";
    }
    &.i-export:before {
        content: "\e93c";
    }
    &.i-x-after:after {
        content: "\e926";
    }
    &.i-move:before {
        content: "\e95e";
    }
    &.i-add:before {
        content: "\e960";
    }
    &.i-ad_type_2:before {
        content: "\e982";
    }
    &.i-copy:before {
        content: "\e95b";
    }
    &.i-on-off:before {
        content: "\e95c";
    }
    &.i-type:before {
        content: "\e965";
    }
    &.i-ssp:before {
        content: "\e966";
    }

    &.i-cat:before {
        content: "\e94f";
    }
    &.i-content:before {
        content: "\e950";
        font-size: 1.75rem;
    }

    &.i-remove:before {
        content: "\e963";
    }
    &.i-user-add:before {
        content: "\e905";
    }
    &.i-msg:before {
        content: "\e906";
    }
    &.i-ads:before {
        content: "\e94c";
    }

    &.i-edit:before {
        content: "\e907";
    }
    &.i-payment:before {
        content: "\e908";
    }
    &.i-filter:before {
        content: "\e909";
    }
    &.i-import:before {
        content: "\e90a";
    }
    &.i-month:before {
        content: "\e90aa";
    }
    &.i-calendar:after {
        content: "\e934";
    }
    &.i-calendar-b:before {
        content: "\e934";
    }
    &.i-article:before {
        content: "\e90b";
        font-size: 1.75rem;
    }
    &.i-star-full:before {
        content: "\e90c";
    }
    &.i-star-stroke:before {
        content: "\e90d";
    }
    &.i-template:before {
        content: "\e951";
    }
    &.i-delete:before {
        content: "\e90e";
    }
    &.i-undelete:before {
        content: "\e95d";
    }
    &.i-user:before {
        content: "\e910";
    }
    .i-repeatable:before {
        content: "\e96c";
    }

    &.i-user2:after {
        content: "\e910";
    }
    &.i-auto-complete:before {
        content: "\e93e";
    }
    &.i-search2:before {
        content: "\e911";
    }
    &.i-settings:before {
        content: "\e912";
    }
    &.i-notice-alert:before {
        content: "\e90f";
    }
    &.i-log:before {
        content: "\e92a";
    }
    &.i-arrow:before {
        content: "\e92a";
        transform: rotate(-45deg);
    }
    &.i-circle:before {
        content: "\e99d";
    }
    &.i-notice-error:before {
        content: "\e919";
    }
    &.i-notice-success:before {
        content: "\e91a";
    }
    &.i-check:before {
        content: "\e914";
    }
    &.i-insert:before {
        content: "\e953";
        transform: rotate(-90deg);
        font-size: 1.75rem;
    }

    &.i-dash:after {
        content: "\e924";
    }
    &.i-gallery:before {
        content: "\e92e";
    }
    &.i-tooltips:before {
        content: "\e929";
    }
    &.i-task-group:before {
        content: "\e99c";
    }
    &.i-arrow-right:before {
        content: "\e957";
        transform: rotate(-90deg);
    }
    &.i-close-2:before {
        content: "\e902";
    }
    &.i-cancel-thin:before {
        content: "\e961";
    }
    &.i-save-thin:before {
        content: "\e962";
    }
    &.i-updownload:before {
        content: "\e92c";
    }
    &.i-scedule:before {
        content: "\e99f";
    }

    &.i-done:before {
        content: "\e92f";
    }
    &.i-notdone:before {
        content: "\e930";
    }
    &.i-in-progress:before {
        content: "\e931";
    }
    &.i-sheet:before {
        content: "\e933";
    }
    &.i-data:before {
        content: "\e935";
    }
    &.i-refresh:before {
        content: "\e936";
    }
    &.i-refresh-2:before {
        content: "\e99c";
    }
    &.i-fb:before {
        content: "\e942";
    }
    &.i-expand:before {
        content: "\e937";
        font-size: 1.35rem;
    }
    &.i-geo:before {
        content: "\e94d";
    }
    &.i-prebid-conf:before {
        content: "\e99e";
    }
    &.i-simplify:before {
        content: "\e938";
        font-size: 1.35rem;
    }
    &.i-url:before {
        content: "\e93b";
    }
    &.i-podium:before {
        content: "\e93a";
    }
    &.i-page:before {
        content: "\e93d";
    }
    &.i-click:before {
        content: "\e93c";
    }
    &.i-task:before {
        content: "\e939";
    }
    &.i-click-t:before {
        content: "\e940";
    }
    &.i-click:before {
        content: "\e941";
    }
    &.i-impression:before {
        content: "\e998";
    }
    &.i-article:before {
        content: "\e943";
    }
    &.i-config:before {
        content: "\e952";
    }
    &.i-compare:before {
        content: "\e944";
    }
    &.i-article-a:after {
        content: "\e943";
    }
    &.i-unit-sidebar:before {
        content: "\e96f";
    }
    &.i-unit-header:before {
        content: "\e96a";
    }
    &.i-unit-content:before {
        content: "\e96b";
    }
    &.i-txt:before {
        content: "\e97e";
    }
    &.i-dependencies:before {
        content: "\e97f";
    }
    &.i-reschedule:before {
        content: "\e98d";
    }

    &.i-unit-footer:before {
        content: "\e969";
    }
    &.i-unit:before {
        content: "\e974";
    }
    &.i-unit:before {
        content: "\e974";
    }
    &.i-video:before {
        content: "\e9a9";
    }

    &.i-ad_template:before {
        content: "\e97c";
    }
    &.i-ad_template_2:before {
        content: "\e981";
    }
    &.i-connection:before {
        content: "\e9c4";
    }
    &.i-ab-testing:before {
        content: "\e9c5";
    }
    &.i-inject:before {
        content: "\e9c2";
    }
    &.i-network:before {
        content: "\e9b4";
    }
    &.i-behaviour:before {
        content: "\e9c0";
    }
    &.i-ad_type:before {
        content: "\e97a";
    }
    &.i-bidder:before {
        content: "\e97b";
    }
    &.i-template_types:before {
        content: "\e97d";
    }
    &.i-sspConfig:before {
        content: "\e983";
    }
    &.i-TS:before, &.i-ts:before {
        content: "\e916";
    }
    &.i-TR:before, &.i-tr:before {
        content: "\e917";
    }
    &.i-SR:before, &.i-sr:before {
        content: "\e918";
    }
    &.i-TT:before, &.i-tt:before {
        content: "\e918a";
    }
    &.i-TH:before, &.i-th:before {
        content: "\e91c";
    }
    &.i-BG:before, &.i-bg:before {
        content: "\e91d";
    }
    &.i-CB:before, &.i-cbr:before {
        content: "\e91e";
        font-size: 1.3em;
    }
    &.i-TG:before, &.i-tg:before {
        content: "\e91f";
    }
    &.i-TQ:before, &.i-tq:before {
        content: "\e920";
    }
    &.i-HC:before, &.i-hc:before {
        content: "\e921";
        font-size: 1.2em;
    }
    &.i-TV:before, &.i-tv:before {
        content: "\e922";
    }
    &.i-MM:before, &.i-mm:before {
        content: "\e923";
    }
    &.i-RE:before, &.i-re:before {
        content: "\e924";
    }
    &.i-QC:before, &.i-qc:before {
        content: "\e915";
    }
    &.i-MB:before, &.i-mb:before {
        content: "\e925";
    }
    &.i-MC:before, &.i-mc:before {
        content: "\e91b";
    }
    &.i-CH:before, &.i-ch:before {
        content: "\e927";
        font-size: 1.3em;
    }
    &.i-TD:before, &.i-td:before {
        content: "\e928";
    }
    &.i-GR:before, &.i-gr:before {
        content: "\e92d";
    }
    &.i-RT:before, &.i-rt:before {
        content: "\e92b";
        font-size: 1.2em;
    }
}
